import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  AiOutlineShopping,
  AiOutlineFileText,
  AiOutlineUser,
  AiOutlineFileSearch,
  AiOutlineIdcard,
  AiOutlineHome,
  AiOutlineShoppingCart // Ajout de l'import pour l'icône du panier
} from "react-icons/ai"; // Importation des icônes depuis react-icons
import dashboardRoutes from "../routes";
import Sidebar from "../components/Sidebar/Sidebar";

// Styles pour appliquer la police Century Gothic et les couleurs
const cardTextStyle = {
  fontFamily: "Century Gothic, sans-serif",
};

const welcomeStyle = {
  fontFamily: "'Century Gothic', sans-serif",
  fontSize: '2rem',
  fontWeight: 'bold',
  color: '#113c60',
  marginBottom: '10px',
};

function Home() {
  const [hoveredCard, setHoveredCard] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  // Fonction pour rendre l'icône en fonction du nom de la classe icon
  function renderIcon(iconClassName) {
    switch (iconClassName) {
      case "nc-icon nc-bag":
        return <AiOutlineShopping size={30} color="#435aa0" />;
      case "nc-icon nc-notes":
        return <AiOutlineFileText size={30} color="#435aa0" />;
      case "nc-icon nc-circle-09":
        return <AiOutlineUser size={30} color="#435aa0" />;
      case "nc-icon nc-paper-2":
        return <AiOutlineFileSearch size={30} color="#435aa0" />;
      case "nc-icon nc-badge":
        return <AiOutlineIdcard size={30} color="#435aa0" />;
      case "nc-icon nc-cart-simple":
        return <AiOutlineShoppingCart size={30} color="#435aa0" />; // Utilisation de AiOutlineShoppingCart pour nc-icon nc-cart-simple
      case "nc-icon nc-bank":
        return <AiOutlineHome size={30} color="#435aa0" />;
      default:
        return null;
    }
  }

  return (
    <Container fluid className="py-5">
      <Row className="justify-content-center">
        <Col md="10" className="text-center">
          <h2 className="mt-1">
            <span style={welcomeStyle}>Bienvenue</span> sur notre ERP
          </h2>
          <p className="lead mb-4">
            Gerez efficacement vos ressources et processus metier.
          </p>
          <Row className="justify-content-center">
            {dashboardRoutes.map((route, index) => {
              // Exclure les routes User Profile et Administrateur
              if (route.name !== "Accueil" && route.name !== "User Profile" && route.name !== "Administrateur" && route.name !== "Historique des articles" && route.name !== "Historique des devis") {
                return (
                  <Col key={index} md="4" className="mb-4">
                    <Card
                      as={Link}
                      to={`${route.layout}${route.path}`}
                      style={{
                        backgroundColor: hoveredCard === index ? "#e0e0e0" : "#f0f0f0",
                        color: "#333",
                        border: "1px solid #ccc",
                        transition: "background-color 0.3s ease"
                      }}
                      className="h-100"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Card.Body className="d-flex flex-column justify-content-center align-items-center" style={cardTextStyle}>
                        {renderIcon(route.icon)} {/* Utilisation de la fonction renderIcon */}
                        <Card.Title className="mt-3 mb-3">{route.name}</Card.Title>
                        <Card.Text className="text-center">
                          {route.description}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              }
              return null;
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
