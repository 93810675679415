import React, { useState, useEffect, useContext } from "react";
import { Card, Container, Row, Col, Table, Form, Button, Modal } from "react-bootstrap";
import { AuthContext } from '../components/LoginPage/LoginPage.js';
import axios from 'axios';
import useWindowSize from '../assets/config/WindowSize.js';
import { FaTrash, FaPen } from "react-icons/fa";
import { useLocation, useHistory } from 'react-router-dom';

function ConsultationArticles() {
  const [articles, setArticles] = useState([]);
  const [searchDocument, setSearchDocument] = useState("");
  const [SelectedEtab, setSelectedEtab] = useState("");
  const { userDetails } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const size = useWindowSize();
  const isMobile = size.width <= 768;
  
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedArticle, setSelectedArticle] = useState(null); // State to store selected article for deletion

  useEffect(() => {
    if (userDetails?.mail) {
      axios.get('/etab_by_mail?email=' + userDetails.mail)
        .then(response => {
          const etab = response.data[0];
          setSelectedEtab(etab.default_soc);
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [userDetails.mail]);

  useEffect(() => {
    if (SelectedEtab) {
      axios.get(`/article?soc=${SelectedEtab}`)
        .then(response => {
          setArticles(response.data);
        })
        .catch(error => console.error('Error fetching articles:', error));
    }
  }, [SelectedEtab]);

  const handleSearchChange = (e) => {
    setSearchDocument(e.target.value);
  };  

  const filteredArticles = articles.filter((article) =>
    article.codeart.toLowerCase().includes(searchDocument.toLowerCase()) ||
    article.lib01.toLowerCase().includes(searchDocument.toLowerCase())
  );

  const handleEdit = (article) => {
    history.push({
      pathname: '/admin/articles/creation',
      state: { articleCode: article.codeart }
    });
  };

  const handleDelete = (article) => {
    axios.post('/delete_article', {
      soc: SelectedEtab,           // Établissement
      code: article.codeart        // Code article
    })
    .then(() => {
      setArticles(articles.filter(a => a.codeart !== article.codeart));
    })
    .catch(error => console.error('Erreur lors de la suppression:', error));
  };
  
  

  const handleShowModal = (article) => {
    setSelectedArticle(article);  
    setShowModal(true);           
  };

  const confirmDelete = () => {
    if (selectedArticle) {
      handleDelete(selectedArticle);  // Supprime l'article sélectionné
    }
    setShowModal(false); // Ferme la modale après confirmation
  };


  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  const renderStep = () => {
    return (
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4" style={welcomeStyle}>Historique des Articles</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form.Group as={Row} controlId="searchArticle">
                  <Form.Label column sm="2">Recherche Article</Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="text"
                      placeholder="Entrez le code de l'article"
                      value={searchDocument}
                      onChange={handleSearchChange}
                    />
                  </Col>
                </Form.Group>
                <Table responsive className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Désignation</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredArticles.length > 0 ? (
                      filteredArticles.map((article, index) => (
                        <tr key={index}>
                          <td>{article.lib01}</td>
                          <td>
                            <div>
                              <FaPen
                                className="text-warning"
                                style={{ cursor: "pointer", marginRight: "10px" }}
                                onClick={() => handleEdit(article)}
                                /> 
                                <FaTrash
                                  className="text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleShowModal(article)}
                                 /> 
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center">Aucun article correspondant trouvé.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation de suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>Êtes-vous sûr de vouloir supprimer cet article ? Cette action est irréversible.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Annuler</Button>
            <Button variant="danger" onClick={confirmDelete}>Supprimer</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }

  return (
    <Container fluid>
      {isMobile ? renderStep() : (
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4" style={welcomeStyle}>Historique des Articles</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form.Group as={Row} controlId="searchArticle">
                  <Form.Label column sm="2">Recherche Article</Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="text"
                      placeholder="Entrez le code de l'article"
                      value={searchDocument}
                      onChange={handleSearchChange}
                    />
                  </Col>
                </Form.Group>
                <Table responsive className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Etablissement</th>
                      <th>Code</th>
                      <th>Désignation</th>
                      <th>Prestation</th>
                      <th>Min/m²</th>
                      <th>Coût/AP/Heure</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredArticles.length > 0 ? (
                      filteredArticles.map((article, index) => (
                        <tr key={index}>
                          <td>{SelectedEtab}</td>
                          <td>{article.codeart}</td>
                          <td>{article.lib01}</td>
                          <td>{article.section}</td>
                          <td>{article.temps}</td>
                          <td>{article.coutrevient}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Button
                                variant="warning"
                                className="me-2"
                                onClick={() => handleEdit(article)}
                              >
                                <FaPen /> 
                              </Button>
                              <Button
                                  variant="danger"
                                  className="me-2"
                                  onClick={() => handleShowModal(article)}
                                >
                                  <FaTrash /> 
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center">Aucun article correspondant trouvé.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation de suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>Êtes-vous sûr de vouloir supprimer cet article ? Cette action est irréversible.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Annuler</Button>
            <Button variant="danger" onClick={confirmDelete}>Supprimer</Button>
          </Modal.Footer>
        </Modal>
    </Container>
  );
}

export default ConsultationArticles;
