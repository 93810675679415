import React, { useState, useEffect } from "react";
import { Button, Card, Container, Row, Col, Table, Modal, Form } from "react-bootstrap";
import { FaTrash, FaPen } from "react-icons/fa";

function HistoriqueDevis() {
  const [devis, setDevis] = useState([
    // Exemple de données
    { id: 1, ofNumber: "12345", client: "123 - John Doe", service: "Menage", amount: "500€" },
    { id: 2, ofNumber: "54321", client: "456 - Jane Smith", service: "Espace Vert", amount: "300€" },
  ]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentDevis, setCurrentDevis] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [searchDocument, setSearchDocument] = useState(""); // État pour la recherche par numéro de document

  useEffect(() => {
    // Détecter la version mobile en fonction de la largeur de l'écran
    const checkIsMobile = () => setIsMobile(window.innerWidth < 768);
    checkIsMobile(); // Vérifier une première fois lors du montage
    window.addEventListener("resize", checkIsMobile); // Ajouter un écouteur pour les redimensionnements
    return () => window.removeEventListener("resize", checkIsMobile); // Nettoyer l'écouteur
  }, []);

  // Gérer l'édition d'un devis existant
  const handleEdit = (devis) => {
    setCurrentDevis(devis);
    setShowEditModal(true);
  };

  // Supprimer un devis
  const handleDelete = (id) => {
    setDevis(devis.filter((devis) => devis.id !== id));
  };

  // Sauvegarder les modifications apportées à un devis
  const handleSave = () => {
    if (currentDevis) {
      setDevis(devis.map((d) => (d.id === currentDevis.id ? currentDevis : d)));
    }
    handleCloseModal();
  };

  // Gérer les changements dans le formulaire de modification
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentDevis({ ...currentDevis, [name]: value });
  };

  // Fermer le modal d'édition
  const handleCloseModal = () => {
    setShowEditModal(false);
    setCurrentDevis(null);
  };

  // Gérer la recherche par numéro de document
  const handleSearchChange = (e) => {
    setSearchDocument(e.target.value);
  };

  // Filtrer les devis en fonction de la recherche
  const filteredDevis = devis.filter((d) => 
    d.ofNumber.toLowerCase().includes(searchDocument.toLowerCase())
  );

  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4" style={welcomeStyle}>Historique des Devis</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="2">
                  Recherche Document
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Entrez le numéro du document"
                    value={searchDocument}
                    onChange={handleSearchChange}
                  />
                </Col>
              </Form.Group>
              <Table responsive className="table-hover table-striped">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>Document</th>
                    <th>Tiers</th>
                    {!isMobile && <th>Type Prestation</th>}
                    <th>Montant</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDevis.length > 0 ? (
                    filteredDevis.map((devis) => (
                      <tr key={devis.id}>
                        <td>
                          {isMobile ? (
                            <div>
                              <FaPen
                                className="text-warning"
                                style={{ cursor: "pointer", marginRight: "10px" }}
                                onClick={() => handleEdit(devis)}
                              />
                              <FaTrash
                                className="text-danger"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDelete(devis.id)}
                              />
                            </div>
                          ) : (
                            <>
                              <Button variant="warning" onClick={() => handleEdit(devis)}>
                                <FaPen /> 
                              </Button>{" "}
                              <Button variant="danger" onClick={() => handleDelete(devis.id)}>
                                <FaTrash /> 
                              </Button>
                            </>
                          )}
                        </td>
                        <td>{devis.ofNumber}</td>
                        <td>{devis.client}</td>
                        {!isMobile && <td>{devis.service}</td>}
                        <td>{devis.amount}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        Il n'y a pas de document associé à ces informations
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showEditModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier le Devis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentDevis && (
            <Form>
              <Form.Group>
                <Form.Label>Numero OF</Form.Label>
                <Form.Control
                  type="text"
                  name="ofNumber"
                  value={currentDevis.ofNumber}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Client (Numero + Nom)</Form.Label>
                <Form.Control
                  type="text"
                  name="client"
                  value={currentDevis.client}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {!isMobile && (
                <Form.Group>
                  <Form.Label>Type Prestation</Form.Label>
                  <Form.Control
                    as="select"
                    name="service"
                    value={currentDevis.service}
                    onChange={handleInputChange}
                  >
                    <option value="Menage">Menage</option>
                    <option value="Espace Vert">Espace Vert</option>
                  </Form.Control>
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label>Montant</Form.Label>
                <Form.Control
                  type="text"
                  name="amount"
                  value={currentDevis.amount}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default HistoriqueDevis;
