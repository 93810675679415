import React, { useState, useEffect, useContext } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { AuthContext } from '../LoginPage/LoginPage';
import { useMsal } from "@azure/msal-react";
import { getUserDetails } from "../../assets/config/graph";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import '../../assets/css/Sidebar.css';

function Sidebar({ color, image, routes }) {
  const location = useLocation();
  const { handleLogout } = useContext(AuthContext);
  const { instance, accounts } = useMsal();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [userProfile, setUserProfile] = useState(null);
  const [activeMainMenu, setActiveMainMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 992);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (accounts.length > 0) {
        try {
          const userDetailsResponse = await getUserDetails(instance, accounts);
          setUserProfile(userDetailsResponse);
        } catch (error) {
          console.error("Error fetching user details: ", error);
        }
      }
    };

    fetchUserProfile();
  }, [instance, accounts]);

  const handleMainMenuClick = (menuName) => {
    if (activeMainMenu === menuName) {
      setActiveMainMenu(null); // Collapse if already active
    } else {
      setActiveMainMenu(menuName);
    }
  };

  const handleSubMenuClick = (subMenuName) => {
    setActiveSubMenu(subMenuName);
  };

  const isActiveRoute = (path) => location.pathname.startsWith(path);

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div className="sidebar-background" style={{ backgroundImage: `url(${image})` }} />
      <div className="sidebar-wrapper d-flex flex-column justify-content-between">
        <div>
          <div className="logo d-flex flex-column align-items-center justify-content-center">
            <div className="user-img">
              <img src={require("../../assets/img/user.png")} alt="User Profile" />
            </div>
            <a className="simple-text">{userProfile ? `${userProfile.givenName} ${userProfile.surname}` : ""}</a>
          </div>
          <Nav>
            {routes.filter(route => route.showInSidebar !== false).map((prop, key) => {
              if (!prop.redirect) {
                // Bloc pour Devis
                if (prop.name === "Devis") {
                  return (
                    <li key={key} id="devis-h" className={`nav-link-parent ${activeMainMenu === 'Devis' ? 'active' : ''}`}>
                      <NavLink to="#" className="nav-link" onClick={() => handleMainMenuClick('Devis')}>
                        <i className="nc-icon nc-single-copy-04" />
                        Devis
                        <FontAwesomeIcon icon={activeMainMenu === 'Devis' ? faChevronUp : faChevronDown} className="chevron-icon" />
                      </NavLink>
                      {activeMainMenu === 'Devis' && (
                        <ul className="submenu">
                          <li className={activeSubMenu === 'creation' ? 'active' : ''}>
                            <NavLink to="/admin/devis/creation" className="nav-link" onClick={() => handleSubMenuClick('creation')}>
                              <i className="nc-icon nc-settings-90" />
                              Création
                            </NavLink>
                          </li>
                          <li className={activeSubMenu === 'historique' ? 'active' : ''}>
                            <NavLink to="/admin/devis/historique" className="nav-link" onClick={() => handleSubMenuClick('historique')}>
                              <i className="nc-icon nc-time-alarm" />
                              Historiques
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </li>
                  );
                }

                // Bloc pour Article
                if (prop.name === "Articles") {
                  return (
                    <li id="article-h" className={`nav-link-parent ${activeMainMenu === 'Articles' ? 'active' : ''}`}>
                      <NavLink to="#" className="nav-link" onClick={() => handleMainMenuClick('Articles')}>
                        <i className="nc-icon nc-delivery-fast" />
                        Articles
                        <FontAwesomeIcon icon={activeMainMenu === 'Articles' ? faChevronUp : faChevronDown} className="chevron-icon" />
                      </NavLink>
                      {activeMainMenu === 'Articles' && (
                        <ul className="submenu">
                          <li className={activeSubMenu === 'creation' ? 'active' : ''}>
                            <NavLink to="/admin/articles/creation" className="nav-link" onClick={() => handleSubMenuClick('creation')}>
                              <i className="nc-icon nc-simple-add" />
                              Création
                            </NavLink>
                          </li>
                          <li className={activeSubMenu === 'historique' ? 'active' : ''}>
                            <NavLink to="/admin/articles/historique" className="nav-link" onClick={() => handleSubMenuClick('historique')}>
                              <i className="nc-icon nc-time-alarm" />
                              Historiques
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </li>
                  );
                }

                return (
                  <li key={key} className={`nav-link-parent ${activeMainMenu === prop.name ? 'active' : ''}`}>
                    <NavLink to={prop.layout + prop.path} className={`nav-link ${isActiveRoute(prop.layout + prop.path) ? 'active' : ''}`} onClick={(e) => handleMainMenuClick(prop.name)}>
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              }
              return null;
            })}
          </Nav>
        </div>
        {isMobile && (
          <div className="sidebar-footer">
            <div className="account-section" style={{ borderTop: "1px solid rgba(255, 255, 255, 0.2)" }}>
              <Nav>
                <li>
                  <NavLink to="/admin/admin-panel" className={`nav-link ${isActiveRoute("/admin/admin-panel") ? 'active' : ''}`}>
                    <i className="nc-icon nc-settings-90" />
                    <p>Administrateur</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/user-profile" className={`nav-link ${isActiveRoute("/admin/user-profile") ? 'active' : ''}`}>
                    <i className="nc-icon nc-single-02" />
                    <p>Mon compte</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/logout" className="nav-link" onClick={handleLogout}>
                    <i className="nc-icon nc-button-power" />
                    <p>Déconnexion</p>
                  </NavLink>
                </li>
              </Nav>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
