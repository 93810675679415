import React, { useState, useEffect , useContext } from 'react';
import { FaMinus, FaPlus, FaCheck, FaTrash } from "react-icons/fa";
import { Container, Row, Col, Table, Nav, Card, Form, Button, Modal, CardImg } from 'react-bootstrap';
import { FaQuestionCircle, FaInfoCircle } from 'react-icons/fa';
import useWindowSize from '../assets/config/WindowSize.js';
import { AuthContext } from '../components/LoginPage/LoginPage.js';
import axios from 'axios'
import Select from 'react-select';
import cancelLogo from '../assets/img/annuler.png';
import distanceLogo from '../assets/img/iconDistance.png';
import changeTextLogo from '../assets/img/modifier.png';
import listLogo from '../assets/img/iconList.png';
import productLogo from '../assets/img/iconProduct.png';
import amountLogo from '../assets/img/amountIcon.png';


function Devis() {
  const size = useWindowSize();
  const isMobile = size.width <= 768;
  const { handleLogout, userDetails } = useContext(AuthContext);
  const [key, setKey] = useState('0020');
  const [currentPage, setCurrentPage] = useState(0);
  const [etablissement, setDefaultEtab] = useState("");
  const [SelectedEtab, setSelectedEtab] = useState([]);
  const [SelectedClient, setSelectedClient] = useState("");
  const [SelectedSpecif, setSelectedSpecif] = useState([]);
  const [SelectedArticle, setSelectedArticle] = useState([]);
  const [showCriteriaPopup, setShowCriteriaPopup] = useState(false);
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [client, setDefaultClient] = useState("");
  const [delai, setDelai] = useState("");
  const handleCriteriaPopupShow = () => setShowCriteriaPopup(true);
  const handleCriteriaPopupClose = () => setShowCriteriaPopup(false);
  const handleHelpPopupShow = () => setShowHelpPopup(true);
  const handleHelpPopupClose = () => setShowHelpPopup(false);
  const handleModalOpen = () => setShowModal(true);
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const [numeroDocument, setNumeroDocument] = useState("000001");
  const [clientInfo, setClientInfo] = useState({});
  const [distance, setDistance] = useState(0);
  const [nombreVehicule, setnombreVehicule] = useState(0);
  const [nombreTrajet, setnombreTrajet] = useState(0);
  const [tempsTrajet, settempsTrajet] = useState(0);
  const [prixMateriel, setPrixMateriel] = useState(0);
  const [prixProduit, setPrixProduit] = useState(0);
  const [marge, setMarge] = useState(0);
  const [articles, setArticles] = useState([]);
  const [distanceOptions, setDistanceOptions] = useState([]);
  const [articleList, setarticleList] = useState([]);
  const [specifList, setSpecifList] = useState([]);
  const [specif, setSpecif] = useState([]);
  const [specificities, setSpecificities] = useState([]);
  const [specifVar, setSpecifVar] = useState([]);
  const [defaultTaux, setDefaultTaux] = useState(0);
  const [majorationTotale, setMajorationTotale] = useState(12.00);
  const [isDisabledIntervention, setIsDisabledIntervention] = useState(false);
  const [isDisabledSuperficie, setIsDisabledSuperficie] = useState(false);
  const [isDisabledReal, setIsDisabledReal] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [wording, setWording] = useState('');

  // etats pour le formulaire "Menage"
  const [nombrePiecesMenage, setNombrePiecesMenage] = useState(0);
  const [nombrePassagesMenage, setNombrePassagesMenage] = useState(0);
  const [superficieMenage, setSuperficieMenage] = useState(0);
  const [specificitesMenage, setSpecificitesMenage] = useState([]);
  const [specificiteMenage, setSpecificiteMenage] = useState('');
  const [tempsMenage, setTempsMenage] = useState(0);



  // etats pour le formulaire "Espace Vert"
  const [nombreInterventionsEspaceVert, setNombreInterventionsEspaceVert] = useState(0);
  const [prestationExterne, setPrestationExterne] = useState(0);
  const [superficieEspaceVert, setSuperficieEspaceVert] = useState(0);
  const [specificitesEspaceVert, setSpecificitesEspaceVert] = useState([]);
  const [specificiteEspaceVert, setSpecificiteEspaceVert] = useState('');
  const [tempsEV, setTempsEV] = useState(0);

  const [tempCode, setTempCode] = useState(''); 
  
  const handleEtabChange = (option) => {
    setSelectedEtab(option);
  };

  const handleClientChange = (option) => {
    setSelectedClient(option);
    if (client[option.value]) {
      setClientInfo(client[option.value]);
    }
  };

  const handleSpecifChange = (option) => {
    setSelectedSpecif(option);
  };

  const handleArticleChange = (option) => {

    if(option.value) {
    
    const temp = {
      value: [option.value[0], option.value[1], option.value[2], option.value[3], option.value[4], option.value[5], wording],
      label: option.value[1],
    }

    setSelectedArticle(temp);

    } 
  }

  useEffect(() => {
    if(showInput && wording != '' ) {
      handleArticleChange(SelectedArticle);
    }
  }, [wording]);

  const handleClickInput = () => {
    setShowInput(!showInput);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const today = new Date().toISOString().split('T')[0];

  const handleDateChange = (e) => {
    setDelai(e.target.value);
  };

  useEffect(() => {
    if (userDetails?.mail) {
      axios.get('/etab_by_mail?email=' + userDetails.mail)
        .then(response => {
          const data = {};
          const defaultSoc = '';
          response.data.forEach(etab => {
          data[etab.etab_soc] = {
            etab_nom: etab.etab_nom,
          };
          setSelectedEtab({value: etab.default_soc, label: `${etab.default_soc} - ${data[etab.default_soc].etab_nom}`});
        });
        setDefaultEtab(data);
      })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [userDetails.mail]);

  useEffect(() => {
    if (userDetails?.mail) {
      axios.get('/taux?soc=' + SelectedEtab.value + '&sec=' + key)
        .then(response => {
          let data = 0;
          response.data.forEach(taux => {
          data = taux.taux;
        });
        setDefaultTaux(data);
      })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [SelectedEtab, key]);

  useEffect(() => {
    if (SelectedEtab) {
      axios.get('/client?soc=' + SelectedEtab.value)
        .then(response => {
          const data = {};
          response.data.forEach(client => {
            data[client.cpt] = {
              nom: client.nom,
              rue1: client.rue1,
              rue2: client.rue2,
              rue3: client.rue3,
              bp: client.bp,
              cp: client.cp,
              ville: client.ville,
              pays: client.pays,
              code_pays: client.code_pays,
              siret: client.siret,
              tva: client.tva,
              ape: client.ape,
              type: client.type,
              activite: client.activite,
            };
          });
  
          // Mettre à jour les options des clients
          const clientOptions = Object.keys(data).map((type) => ({
            value: type,
            label: `${type} - ${data[type].nom}`,
          }));
  
          setDefaultClient(data);  // Mettre à jour les clients par défaut
          setClientOptions(clientOptions);  // Appeler setClientOptions avec les nouvelles options
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [SelectedEtab]);

  const handleRemoveArticleMenage = (index) => {
    const newArticles = articles.filter((_, i) => i !== index);
    const removedArticle = articles[index];
  
    const calculerTempsTravail = (article) => {
      let qte = parseFloat(article.qte);
      let tempsTravail = (article.superficie/qte) * article.nombrePieces * article.nombrePassages;
      return tempsTravail;
    };
  
    const beforeRemovedTime = articles.reduce((total, article) => total + calculerTempsTravail(article), 0);

    const removedTime = calculerTempsTravail(removedArticle);
    const newTime = beforeRemovedTime - removedTime;
  
    setArticles(newArticles);
    setTempsMenage(newTime);
  };

  const handleRemoveArticleEspaceVert = (index) => {
    const newArticles = articles.filter((_, i) => i !== index);
    const removedArticle = articles[index];
  
    const calculerTempsTravail = (article) => {
      let tempsBase = parseFloat(article.qte);
      let tempsTravail = ((article.superficieEV/tempsBase)*60) * article.nombreInterventions;
      return tempsTravail;
    };
  
    const beforeRemovedTime = articles.reduce((total, article) => total + calculerTempsTravail(article), 0);
    const removedTime = calculerTempsTravail(removedArticle);
    const newTime = beforeRemovedTime - removedTime;
  
    setArticles(newArticles);
    setTempsEV(newTime);
  };

  useEffect(() => {
    if (SelectedEtab) {
      axios.get('/article_by?soc=' + SelectedEtab.value + '&sec=' + key)
        .then(response => {
          const data = {};
          response.data.forEach(article => {
            data[article.lib01] = {
              codeart: article.codeart,
              lib01 : article.lib01,
              lib02 : article.lib02,
              section: article.section,
              taxe: article.taxe,
              imputachat: article.imputachat,
              imputvente: article.imputvente,
              qte: article.qte,
              unitemesure: article.unitemesure,
              unitetemps: article.unitetemps,
              coutrevient: article.coutrevient,
              exppu: article.exppu,
              marge: article.marge,
              rubrique: article.rubrique,
              specif: article.specif,
              gestion: article.gestion,
              nature: article.nature,
            };
          });

          const articleList = Object.keys(data).map((type) => ({
            label: data[type].lib01,
            value: data[type].codeart,
            qte : data[type].qte,
            unimes: data[type].unitemesure,
            unitemps: data[type].unitetemps,
            coutrevient : data[type].coutrevient
          }));

          setarticleList(articleList);
          setArticles([]);
          setShowInput(false);
          setWording('');
          setSelectedArticle([]);
          setSelectedSpecif([]);
          setTempsEV(0);
          setTempsMenage(0);

        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [SelectedEtab, key]);

  useEffect(() => {
    if (SelectedEtab && SelectedArticle.value) {
      axios.get('/specif?soc=' + SelectedEtab.value + '&art=' + SelectedArticle.value[0])
        .then(response => {
          const data = {};
          response.data.forEach(specif => {
            data[specif.id] = {
              codespe : specif.codespe,
              lib01 : specif.lib01,
              lib02 : specif.lib02,
              majoration: specif.majoration,
              prompt: specif.prompt
            };
          });
  
          const specifList = Object.keys(data).map((type) => ({
            
            label: data[type].codespe,
            value: data[type].lib01,
            majoration: data[type].majoration,
            prompt: data[type].prompt
          }));
  
          setSpecifList(specifList);

          if (parseInt(SelectedArticle.value[5]) == 0) {
            setIsDisabledIntervention(true);
            setIsDisabledSuperficie(true);
            setIsDisabledReal(false);
          }
          else {
            setIsDisabledIntervention(false);
            setIsDisabledSuperficie(false);
            setIsDisabledReal(true);
          }

        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [SelectedArticle, key]);

  useEffect(() => {
    if (SelectedEtab) {
      axios.get('/transport?soc=' + SelectedEtab.value)
        .then(response => {
          const data = {};
          response.data.forEach(transport => {
            data[transport.id] = {
              lib: transport.lib,
              cout: transport.cout,
            };
          });
  
          const distanceOptions = Object.keys(data).map((type) => ({
            value: data[type].cout,
            label: `${data[type].lib}`,
          }));
  
          setDistanceOptions(distanceOptions);
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [SelectedEtab]);

  const clientOptions = Object.keys(client).map((type) => ({
    value: type,
    label: `${type} - ${client[type].nom}`,
  }));

  const etabOptions = Object.keys(etablissement).map((type) => ({
    value: type,
    label: `${type} - ${etablissement[type].etab_nom}`,
  }));

  const specifOptions = Object.keys(specifList).map((type) => ({
    value: [specifList[type].label, specifList[type].value, specifList[type].majoration, specifList[type].prompt],
    label: specifList[type].value,
  }));

  const articleOptions = Object.keys(articleList).map((type) => ({
    value: [articleList[type].value, articleList[type].label, articleList[type].qte, articleList[type].unimes, articleList[type].unitemps, articleList[type].coutrevient, wording],
    label: articleList[type].label,
  }));

  const handleSelectChangeDistance = (event) => {
    setDistance(event.target.value);
  };

  const handleAddSpecif = () => {
    if (SelectedSpecif) {
      if (SelectedSpecif.value[3] == 1) {
        const p = window.prompt("Veuillez indiquer le " + SelectedSpecif.value[1].toLowerCase());
        if (p) {
          setSpecificities([...specificities ,`${SelectedSpecif.value[1]} (${p})`]);
          setSpecifVar([...specifVar ,[SelectedSpecif.value,[p]]]);
        }
      } else {
        setSpecificities([...specificities , SelectedSpecif.value[1]]);
        setSpecifVar([...specifVar ,[SelectedSpecif.value,["1"]]]);
      }
      setSelectedSpecif([]);
    }
  };

  const clearFormMenage = () => {
    setNombrePiecesMenage('');
    setSelectedArticle([]);
    setNombrePassagesMenage('');
    setSuperficieMenage('');
    setSpecificities([]);
    setSpecif('');
    setSelectedSpecif([]);
    setDelai('');
  };

  const clearFormEspaceVert = () => {
    setSelectedArticle([]);
    setNombreInterventionsEspaceVert('');
    setPrestationExterne('');
    setSuperficieEspaceVert('');
    setSpecificities([]);
    setSpecif('');
    setSelectedSpecif([]);
    setDelai('');
  };
  
  const calculerMontantMenage = (article) => {

    const tempsTravailMenage = (article.superficie / parseFloat(article.qte)) * article.nombrePassages * article.nombrePieces
    const tempsBaseMenage = (tempsTravailMenage * parseFloat(article.coutrevient));
    setTempsMenage(tempsMenage + tempsTravailMenage);

    let mt_majM = 0;
    for (let spec of article.specificites) {
        const nombrePostes = parseInt(spec[1][0]);
        const nomSpec = spec[0][0];
        if (nomSpec !== undefined) {
            mt_majM += nombrePostes ? (tempsBaseMenage * parseFloat(spec[0][2])) * nombrePostes : tempsBaseMenage * parseFloat(spec[0][2]);
        }
    }

    const montantMenage = tempsBaseMenage + mt_majM;
    return montantMenage.toFixed(2);
};


const calculerMontantEspaceVert = (article) => {

  let montantEV = 0;
  let tempsBaseEV = 0;

  if (!article.prestationExterne) {
    if (article.TypePrestation.startsWith("Tonte")) {
      if (article.nombreInterventions < 5) {
        tempsBaseEV = (article.superficieEV / parseFloat(article.qte)) * parseFloat(article.coutrevient * article.nombreInterventions) * 1.5;
        setTempsEV(tempsEV + ((article.superficieEV / parseFloat(article.qte)) * 60) * article.nombreInterventions);
      } else if (article.nombreInterventions < 3) {
        tempsBaseEV = (article.superficieEV / parseFloat(article.qte)) * parseFloat(article.coutrevient * article.nombreInterventions) * 1;
        setTempsEV(tempsEV + ((article.superficieEV / parseFloat(article.qte)) * 60) * article.nombreInterventions);
      } else {
        tempsBaseEV = (article.superficieEV / parseFloat(article.qte)) * parseFloat(article.coutrevient) * article.nombreInterventions;
        setTempsEV(tempsEV + ((article.superficieEV / parseFloat(article.qte)) * 60) * article.nombreInterventions);
      }
      if (article.superficieEV < 1000) {
        tempsBaseEV *= 1.35;
      }
    } else {
      const tempsTravailEV = (article.superficieEV / parseFloat(article.qte)) * article.nombreInterventions;
      tempsBaseEV = tempsTravailEV * parseFloat(article.coutrevient);
      setTempsEV(tempsEV + (tempsTravailEV * 60));
    }

    let mt_majEV = 0;
    for (let spec of article.specificites) {
      const nombrePostes = parseInt(spec[1][0]);
      const nomSpec = spec[0][0];
      if (nomSpec !== undefined) {
        mt_majEV += nombrePostes ? (tempsBaseEV * parseFloat(spec[0][2]) * nombrePostes) : (tempsBaseEV * parseFloat(spec[0][2]));
      }
    }

    montantEV = tempsBaseEV + mt_majEV;
  } else {

    setTempsEV(tempsEV);
    montantEV = parseFloat(article.prestationExterne);
  }

  return montantEV.toFixed(2);
};


  const handleSubmitArticleMenage = (event) => {

    if (SelectedArticle.value) {

      let lib = SelectedArticle.value[1];

      if (SelectedArticle.value[6].trim() != '') {
        lib = SelectedArticle.value[6]
      }

    event.preventDefault();
    const article = {
      nombrePieces: nombrePiecesMenage,
      code: SelectedArticle.value[0],
      typePiece: lib,
      qte: SelectedArticle.value[2],
      nombrePassages: nombrePassagesMenage,
      superficie: superficieMenage,
      specificites: specificities,
      delai : delai,
      montant: calculerMontantMenage({
        coutrevient: parseFloat(SelectedArticle.value[5]) * (1 + majorationTotale/100),
        qte: SelectedArticle.value[2],
        nombrePieces: nombrePiecesMenage,
        typePiece: SelectedArticle.value[1],
        nombrePassages: nombrePassagesMenage,
        superficie: superficieMenage,
        specificites: specifVar
      })
    };

    if (SelectedArticle.value[6].trim() == '') {
      setShowInput(false);
    }
    setArticles([...articles, article]);
    setSpecifVar([]);
    clearFormMenage();
    setWording('');
    }
  };

  const handleSubmitArticleEspaceVert = (event) => {

    if (SelectedArticle.value) {
    event.preventDefault();
    const article = {
      code: SelectedArticle.value[0],
      TypePrestation: SelectedArticle.value[1],
      qte: SelectedArticle.value[2],
      nombreInterventions: nombreInterventionsEspaceVert,
      prestationExterne : prestationExterne,
      superficieEV: superficieEspaceVert,
      specificites: specificities,
      delai : delai,
      montant: calculerMontantEspaceVert({
        coutrevient: parseFloat(SelectedArticle.value[5]) * (1 + majorationTotale/100),
        prestationExterne : prestationExterne,
        qte: SelectedArticle.value[2],
        TypePrestation: SelectedArticle.value[1],
        nombreInterventions: nombreInterventionsEspaceVert,
        superficieEV: superficieEspaceVert,
        specificites: specifVar
      })
    };
    setArticles([...articles, article]);
    setSpecifVar([]);
    clearFormEspaceVert();
    }
  };

  const calculerMontantTotal = () => {
    const totalArticles = articles.reduce((total, article) => total + parseFloat(article.montant), 0);
    const coutKilometres = (parseFloat(distance) * nombreVehicule + tempsTrajet * (defaultTaux / 60)) * nombreTrajet;
    const prixProduits = parseFloat(prixMateriel) + parseFloat(prixProduit);
    const Marge = 1 + (parseFloat(marge)/100)
    const montantTotal = (totalArticles + coutKilometres + prixProduits);
    const montantTotalHT =  Marge * montantTotal
    return { totalArticles: totalArticles.toFixed(2), coutKilometres: coutKilometres.toFixed(2), prixProduits: prixProduits.toFixed(2), montantTotal: montantTotal.toFixed(2),montantTotalHT: montantTotalHT.toFixed(2) };
  };

  const montantTotal = calculerMontantTotal();

  const handleValidationMenage = () => {
    alert(`Devis valide ! Montant Total Hors Taxe  (Menage) : ${montantTotal.montantTotalHT} €`);
  };

  //console.log(SelectedClient.label.split('- ')[1]);
  const handleValidationEspaceVert = () => {
    alert(`Devis valide ! Montant Total Hors Taxe  (Espace Vert) : ${montantTotal.montantTotalHT} €`);
    //fetch('/insert_devis', {
      //method: 'POST',
      //headers: {
        //'Content-Type': 'application/json'
      //},
      //body: JSON.stringify({
        //soc: SelectedEtab,
        //num_doc: numeroDocument,
        //cpt: SelectedClient.value,
        //nom: SelectedClient.label.split('- ')[1],
        //distance: distance,
        //nb_vehicule: nombreVehicule,
        //cout_prod: prixProduit,
        //cour_mate: prixMateriel,
        //marge: marge,
      //})
    //})
      //.then(response => response.json())
      //.then(data => console.log(data))
      //.catch(error => console.error('Error:', error));
  };

  const renderStep = () => {
    if (key === '0020') {
      switch (currentPage) {
        case 0:
          return (
            <div>
           <Card>
              <Card.Header>
                <Card.Title as="h4" style={welcomeStyle}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Création de devis</span>
                    <div style={{ marginLeft: 'auto' }}>
                      <FaQuestionCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleCriteriaPopupShow} />
                      <FaInfoCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleHelpPopupShow} />
                    </div>
                  </div>
                </Card.Title>
                <div>
                  <Modal show={showCriteriaPopup} onHide={handleCriteriaPopupClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Critères de non réalisation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Pour compléter le formulaire de devis de manière correcte et efficace, veuillez vous assurez qu'il n'y a pas de critère de non réalisation avant de commencer votre devis :
                      </p>
                      <p>
                        - Difficultés d'accès 
                      </p>
                      <p>
                        - Si risque de chutes du fait de l'encombrement matériel du terrain
                      </p>
                      <p>
                        - Condition météo (comme neige, verglas)
                      </p>
                      <p>
                        Ces critères sont très importants et doivent impérativement être pris en compte pour la sécuritée de nos travailleurs, toute action allant à l'encontre de ceux-ci entrainera de lourde sanctions
                      </p>                    
                    </Modal.Body>
                  </Modal>
                  <Modal show={showHelpPopup} onHide={handleHelpPopupClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Aide</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Pour compléter le formulaire de devis de manière correcte et efficace, veuillez suivre les instructions ci-dessous :
                      </p>
                      <p>
                        - Vérifiez les coordonnées 
                      </p>
                      <p>
                        - Renseignez tous les champs
                      </p>
                      <p>
                        - Détaillez les services/produits
                      </p>
                      <p>
                        - Revérifiez les informations
                      </p>
                      <p>
                      Chaque champ est crucial pour la validité du devis. Une donnée manquante ou incorrecte peut entraîner des retards et des complications. Merci de votre coopération.
                      </p>
                    </Modal.Body>
                  </Modal>
                </div>
              </Card.Header>
                <Card.Body>
                  <Row className="mb-1">
                    <Col md="4">
                    <Form.Group>
                          <label>
                            Etablissement :
                          </label>
                          <Select
                            value={SelectedEtab}
                            onChange={handleEtabChange}
                            options={etabOptions}
                            placeholder="Choisir une option..."
                            noOptionsMessage={() => "Pas de résultats"}
                          />
                    </Form.Group>
                    </Col>
                    <Col md="4">
                        <Form.Group>
                          <label>
                            Compte client{' '}
                            {/* Icône "i" cliquable pour ouvrir le modal */}
                            {SelectedClient && (
                              <FaInfoCircle
                                style={{ cursor: 'pointer', marginLeft: '8px' }}
                                onClick={handleModalOpen}
                              />
                            )}
                          </label>
                          <Select
                            value={SelectedClient}
                            onChange={handleClientChange}
                            options={clientOptions}
                            placeholder="Choisir une option..."
                            
                          />
                        </Form.Group>
                      </Col>
                      <Modal show={showModal} onHide={handleModalClose}>
                        <Modal.Header closeButton>
                          {/* ... */}
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center' }}>
                          {/* Affichage conditionnel du nom du client ou message si aucun client n'est sélectionné */}
                          {clientInfo ? (
                            <>
                              {/* Nom du client en gras et majuscules */}
                              <h5 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: '1rem' }}>
                                {clientInfo.nom}
                              </h5>

                              {/* Affichage des autres informations alignées à droite */}
                              <div style={{ textAlign: 'left' }}>
                                {clientInfo.rue1 && <div>{clientInfo.rue1}</div>}
                                {clientInfo.rue2 && <div>{clientInfo.rue2}</div>}
                                {clientInfo.rue3 && <div>{clientInfo.rue3}</div>}
                                {clientInfo.ville && <div>{clientInfo.ville}</div>}
                                {clientInfo.cp && <div>{clientInfo.cp}</div>}
                                {clientInfo.bp && <div>BP : {clientInfo.bp}</div>}
                                {clientInfo.pays && <div>{clientInfo.pays}</div>}
                              </div>
                            </>
                          ) : (
                            // Message à afficher lorsque aucun client n'est sélectionné
                            <h5 style={{ fontWeight: 'bold', color: 'red' }}>
                              Aucun client n'a été sélectionné
                            </h5>
                          )}
                        </Modal.Body>
                      </Modal>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>N° Document</Form.Label>
                        <Form.Control
                          required
                          value={String(numeroDocument).padStart(6, '0')} // Format en 6 chiffres
                          type="number"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
          <Card>
            <Card.Body>
              <Nav variant="tabs" activeKey={key} onSelect={(k) => { setKey(k); setCurrentPage(0); }} className='mb-3'>
                  <Nav.Item>
                    <Nav.Link eventKey="0020">Ménage</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="0013">Espace Vert</Nav.Link>
                  </Nav.Item>
              </Nav>
              <Form.Group>
                <Form.Label>Nombre de pièces</Form.Label>
                <Form.Control
                  required
                  min="1"
                  value={nombrePiecesMenage}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10);
                    if (!isNaN(newValue) && newValue >= 1) {
                      setNombrePiecesMenage(newValue);
                    }
                  }}
                  placeholder="Ex : 3"
                  type="number"
                />
              </Form.Group>
              <Form.Group>
                <label>
                Type de pièce :
                </label>
                <Select
                  value={SelectedArticle}
                  onChange={handleArticleChange}
                  options={articleOptions}
                  placeholder="Choisir une option..."
                  noOptionsMessage={() => "Pas de résultats"}
                  />
              </Form.Group>
              <Form.Group>
                <Form.Label>Nombre de passages par mois</Form.Label>
                <Form.Control
                  required
                  min="1"
                  value={nombrePassagesMenage}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10);
                    if (!isNaN(newValue) && newValue >= 1) {
                      setNombrePassagesMenage(newValue);
                    }
                  }}
                  placeholder="Ex : 3"
                  type="number"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Superficie / pièce (en m²)</Form.Label>
                <Form.Control
                  required
                  min="1"
                  value={superficieMenage}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10);
                    if (!isNaN(newValue) && newValue >= 1) {
                      setSuperficieMenage(newValue);
                    }
                  }}
                  placeholder="Ex : 50"
                  type="number"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Date de fin de prestation :</Form.Label>
                <Form.Control
                  required
                  type="date"
                  value={delai}
                  onChange={handleDateChange}
                  min={today} // Empêche la sélection des dates antérieures à aujourd'hui
                  placeholder="JJ/MM/AAAA"
                />
              </Form.Group>
              <Form.Group>
                <label>
                Spécificités :
                </label>
                <Select
                  value={SelectedSpecif}
                  onChange={handleSpecifChange}//setSpecif([e.value.split(',')[0], e.value.split(',')[1], e.value.split(',')[2], e.value.split(',')[3]])}
                  options={specifOptions}
                  placeholder="Choisir une option..."
                  noOptionsMessage={() => "Pas de résultats"}
                  />
              </Form.Group>
                <Button className='mt-3' onClick={handleAddSpecif}>Ajouter Specificite</Button>
                <Button className='mt-3 ml-2' onClick={handleSubmitArticleMenage}>Valider Article</Button>
              <ul className='mt-2'>
                {specificitesMenage.map((spec, index) => (
                  <li key={index}>{spec}</li>
                ))}
              </ul>
            </Card.Body>
            </Card>
            <Card>
            <Card.Body>
                <Card.Header>
                  <Card.Title as="h5" style={welcomeStyle2} className='text-center'>Liste des articles</Card.Title>
                  <hr class="hr hr-blurry" />
                </Card.Header>
              <Form.Group>
                <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0"></th>
                        {key === '0020' && (
                          <>
                            <th className="border-0">Nbre pièces</th>
                            <th className="border-0">Type de pièce</th>
                            <th className="border-0">Délai</th>
                            <th className="border-0">Montant</th>
                          </>
                        )}
                        {key === '0013' && (
                          <>
                            <th className="border-0">Type de prestation</th>
                            <th className="border-0">Nbre interventions</th>
                            <th className="border-0">Superficie</th>
                            <th className="border-0">Specificites</th>
                            <th className="border-0">Délai</th>
                            <th className="border-0">Montant (€)</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {key === '0020' && articles.map((article, index) => (
                        <tr key={index}>
                          <td>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleRemoveArticleMenage(index)}
                            />
                          </td>
                          <td>{article.nombrePieces}</td>
                          <td>{article.typePiece}</td>
                          <td>{formatDate(article.delai)}</td>
                          <td>{article.montant}</td>
                        </tr>
                      ))}
                      {key === '0013' && articles.map((article, index) => (
                        <tr key={index}>
                          <td>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleRemoveArticleEspaceVert(index)}
                            />
                          </td>
                          <td>{article.typePrestation}</td>
                          <td>{article.superficie}</td>
                          <td>{formatDate(article.delai)}</td>
                          <td>{article.montant}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
              </Form.Group>
              <Button className='float-right mt-3' onClick={() => {setCurrentPage(currentPage + 1)}}>Suivant</Button>
            </Card.Body>
            </Card>
            </div>
          );
        case 1:
          return (
            <div>
            <Card>
                <Card.Header>
                  <Card.Title as="h5" className='mx-3 text-center' style={welcomeStyle2}>Distance (Aller-Retour)</Card.Title>
                  <hr class="hr hr-blurry" />
                </Card.Header>
                <Card.Body>
                <Form.Group className="w-100 mb-3">
                    <Form.Label>Nombre de trajets (A/R):</Form.Label>
                    <Form.Control
                      required
                      value={nombreTrajet}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue >= 0) {
                          setnombreTrajet(newValue);
                        }
                      }}
                      type="number"
                      className="w-100"
                    />
                  </Form.Group>
                <Form.Group className="w-100 mb-3">
                    <Form.Label>Temps de trajet (en min.) :</Form.Label>
                    <Form.Control
                      required
                      value={tempsTrajet}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue >= 0) {
                          settempsTrajet(newValue);
                        }
                      }}
                      type="number"
                      className="w-100"
                    />
                  </Form.Group>
                  <Form.Group className="w-100 mb-3">
                    <Form.Label>Nombre de véhicules :</Form.Label>
                    <Form.Control
                      required
                      value={nombreVehicule}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue >= 0) {
                          setnombreVehicule(newValue);
                        }
                      }}
                      type="number"
                      className="w-100"
                    />
                  </Form.Group>
                  <Form.Group className="w-100 mb-3">
                    <Form.Label>Distance (A/R) :</Form.Label>
                    <Form.Control
                      as="select"
                      value={distance}
                      onChange={handleSelectChangeDistance}
                      className="w-100"
                    >
                      {distanceOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Card.Body>
              </Card>
            <Card>
              <Card.Body>
                    <Card.Header>
                      <Card.Title className='mx-3 text-center' as="h5" style={welcomeStyle2}>Produits/Matériel (€)</Card.Title>
                      <hr class="hr hr-blurry" />
                    </Card.Header>
                    <Form.Group className='w-100'>
                      <Form.Group className='mb-3'>
                        <label>Prix Produits</label>
                        <Form.Control
                          required
                          value={prixProduit}
                          onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10);
                            if (!isNaN(newValue) && newValue >= Number(0)) {
                              setPrixProduit(newValue);
                            }
                          }}
                          type="number"
                        />
                      </Form.Group>
                      <Form.Group className='mb-3'>
                        <label>Prix Matériel</label>
                        <Form.Control
                          required
                          value={prixMateriel}
                          onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10);
                            if (!isNaN(newValue) && newValue >= Number(0)) {
                              setPrixMateriel(newValue);
                            }
                          }}
                          type="number"
                        />
                      </Form.Group>
                    </Form.Group>
              </Card.Body>
              <Card.Footer className="align-items-center">
                <Button className='float-left' onClick={() => setCurrentPage(currentPage - 1)}>Precedent</Button>
                <Button className='float-right' onClick={() => setCurrentPage(currentPage + 1)}>Suivant</Button>
              </Card.Footer>
            </Card>
            </div>
          );
        case 2:
          return (
            <Card className="mt-3">
              <Card.Header>
                <Card.Title className='text-center' as="h4" style={welcomeStyle2}>Montant Total</Card.Title>
                <hr class="hr hr-blurry" />
              </Card.Header>  
                <Card.Body>
                <div style={{ fontSize: "1.1rem" }}>
                        Temps de travail : {(tempsEV / 60).toFixed(2).toString().split('.')[0]}H{(((parseInt((tempsEV / 60).toFixed(2).toString().split('.')[1])) / 100) * 60).toFixed(0)}M
                        <br></br>Deplacement : {montantTotal.coutKilometres} €
                        <br></br>Produits/Matériels : {isNaN(montantTotal.prixProduits) ? 0 : montantTotal.prixProduits} €
                        <br></br>Coût de revient : {montantTotal.montantTotal} €
                        <div className="d-flex align-items-center">
                          Marge (%) :
                          <Form.Control
                            required
                            value={marge || 0}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                setMarge(newValue);
                              }
                            }}
                            placeholder="Marge (%)"
                            type="number"
                            className="ml-2"
                            style={{ width: '100px', height: '30px' }} // Optionnel : pour contrôler la largeur du champ de saisie
                          />
                        </div>
                        <hr class="hr hr-blurry mt-4" />
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          Montant Total (HT) : {montantTotal.montantTotalHT} €
                        </div>
                      </div>
                  <div className="mt-3 d-flex justify-content-center">
                    <Button onClick={() => setCurrentPage(currentPage - 1)}>Precedent</Button>
                    <Button onClick={handleValidationEspaceVert} className="ml-3">Soumettre</Button>
                  </div>
                </Card.Body>
              </Card>
          );
        default:
          return null;
      }
    } else if (key === '0013') {
      switch (currentPage) {
        case 0:
          return (
            <div>
            <Card>
              <Card.Header>
                <Card.Title as="h4" style={welcomeStyle}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Création de devis</span>
                    <div style={{ marginLeft: 'auto' }}>
                      <FaQuestionCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleCriteriaPopupShow} />
                      <FaInfoCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleHelpPopupShow} />
                    </div>
                  </div>
                </Card.Title>
                <div>
                  <Modal show={showCriteriaPopup} onHide={handleCriteriaPopupClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Critères de non réalisation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Pour compléter le formulaire de devis de manière correcte et efficace, veuillez vous assurez qu'il n'y a pas de critère de non réalisation avant de commencer votre devis :
                      </p>
                      <p>
                        - Difficultés d'accès 
                      </p>
                      <p>
                        - Si risque de chutes du fait de l'encombrement matériel du terrain
                      </p>
                      <p>
                        - Condition météo (comme neige, verglas)
                      </p>
                      <p>
                        Ces critères sont très importants et doivent impérativement être pris en compte pour la sécuritée de nos travailleurs, toute action allant à l'encontre de ceux-ci entrainera de lourde sanctions
                      </p>                    
                    </Modal.Body>
                  </Modal>
                  <Modal show={showHelpPopup} onHide={handleHelpPopupClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Aide</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Pour compléter le formulaire de devis de manière correcte et efficace, veuillez suivre les instructions ci-dessous :
                      </p>
                      <p>
                        - Vérifiez les coordonnées 
                      </p>
                      <p>
                        - Renseignez tous les champs
                      </p>
                      <p>
                        - Détaillez les services/produits
                      </p>
                      <p>
                        - Revérifiez les informations
                      </p>
                      <p>
                      Chaque champ est crucial pour la validité du devis. Une donnée manquante ou incorrecte peut entraîner des retards et des complications. Merci de votre coopération.
                      </p>
                    </Modal.Body>
                  </Modal>
                </div>
              </Card.Header>
                <Card.Body>
                  <Row className="mb-1">
                    <Col md="4">
                    <Form.Group>
                          <label>
                            Etablissement :
                          </label>
                          <Select
                            value={SelectedEtab}
                            onChange={handleEtabChange}
                            options={etabOptions}
                            placeholder="Choisir une option..."
                            noOptionsMessage={() => "Pas de résultats"}
                          />
                    </Form.Group>
                    </Col>
                      <Col md="4">
                        <Form.Group>
                          <label>
                            Compte client{' '}
                            {/* Icône "i" cliquable pour ouvrir le modal */}
                            {SelectedClient && (
                              <FaInfoCircle
                                style={{ cursor: 'pointer', marginLeft: '8px' }}
                                onClick={handleModalOpen}
                              />
                            )}
                          </label>
                          <Select
                            value={SelectedClient}
                            onChange={handleClientChange}
                            options={clientOptions}
                            placeholder="Choisir une option..."
                            noOptionsMessage={() => "Pas de résultats"}
                          />
                        </Form.Group>
                      </Col>
                      <Modal show={showModal} onHide={handleModalClose}>
                        <Modal.Header closeButton>
                          {/* ... */}
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center' }}>
                          {/* Affichage conditionnel du nom du client ou message si aucun client n'est sélectionné */}
                          {clientInfo ? (
                            <>
                              {/* Nom du client en gras et majuscules */}
                              <h5 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: '1rem' }}>
                                {clientInfo.nom}
                              </h5>

                              {/* Affichage des autres informations alignées à droite */}
                              <div style={{ textAlign: 'left' }}>
                                {clientInfo.rue1 && <div>{clientInfo.rue1}</div>}
                                {clientInfo.rue2 && <div>{clientInfo.rue2}</div>}
                                {clientInfo.rue3 && <div>{clientInfo.rue3}</div>}
                                {clientInfo.ville && <div>{clientInfo.ville}</div>}
                                {clientInfo.cp && <div>{clientInfo.cp}</div>}
                                {clientInfo.bp && <div>BP : {clientInfo.bp}</div>}
                                {clientInfo.pays && <div>{clientInfo.pays}</div>}
                              </div>
                            </>
                          ) : (
                            // Message à afficher lorsque aucun client n'est sélectionné
                            <h5 style={{ fontWeight: 'bold', color: 'red' }}>
                              Aucun client n'a été sélectionné
                            </h5>
                          )}
                        </Modal.Body>
                      </Modal>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>N° Document</Form.Label>
                        <Form.Control
                          required
                          value={String(numeroDocument).padStart(6, '0')} // Format en 6 chiffres
                          type="number"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
          <Card>
            <Card.Body>
              <Nav variant="tabs" activeKey={key} onSelect={(k) => { setKey(k); setCurrentPage(0); }} className='mb-3'>
                  <Nav.Item>
                    <Nav.Link eventKey="0020">Menage</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="0013">Espace Vert</Nav.Link>
                  </Nav.Item>
              </Nav>
              <Form.Group>
                <label>
                Type de prestation :
                </label>
                <Select
                  value={SelectedArticle}
                  onChange={handleArticleChange}
                  options={articleOptions}
                  placeholder="Choisir une option..."
                  noOptionsMessage={() => "Pas de résultats"}
                  />
                </Form.Group>
                <Form.Group>
                <Form.Label>Nombre d'interventions par mois</Form.Label>
                <Form.Control
                  required
                  min="1"
                  value={nombreInterventionsEspaceVert}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10);
                    if (!isNaN(newValue) && newValue >= 1) {
                      setNombreInterventionsEspaceVert(newValue);
                    }
                  }}
                  placeholder="Ex : 3"
                  type="number"
                  disabled={isDisabledIntervention}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Superficie (en m²)</Form.Label>
                <Form.Control
                  required
                  min="1"
                  value={superficieEspaceVert}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10);
                    if (!isNaN(newValue) && newValue >= 1) {
                      setSuperficieEspaceVert(newValue);
                    }
                  }}
                  placeholder="Ex : 100"
                  type="number"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Date de fin de prestation :</Form.Label>
                <Form.Control
                  required
                  type="date"
                  value={delai}
                  onChange={handleDateChange}
                  min={today} // Empêche la sélection des dates antérieures à aujourd'hui
                  placeholder="JJ/MM/AAAA"
                />
              </Form.Group>
              <Form.Group>
                <label>
                Spécificités :
                </label>
                <Select
                  value={SelectedSpecif}
                  onChange={handleSpecifChange}//setSpecif([e.value.split(',')[0], e.value.split(',')[1], e.value.split(',')[2], e.value.split(',')[3]])}
                  options={specifOptions}
                  placeholder="Choisir une option..."
                  noOptionsMessage={() => "Pas de résultats"}
                  />
              </Form.Group>
                <Button className='mt-3' onClick={handleAddSpecif}>Ajouter Specificite</Button>
                <Button className='mt-3 ml-2' onClick={handleSubmitArticleEspaceVert}>Valider Article</Button>
              <ul className='mt-2'>
                {specificitesEspaceVert.map((spec, index) => (
                  <li key={index}>{spec}</li>
                ))}
              </ul>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
                <Card.Header>
                  <Card.Title as="h5" style={welcomeStyle2} className='text-center'>Liste des articles</Card.Title>
                  <hr class="hr hr-blurry" />
                </Card.Header>
              <Form.Group>
                <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0"></th>
                        {key === '0020' && (
                          <>
                            <th className="border-0">Nbre pièces</th>
                            <th className="border-0">Type de pièce</th>
                            <th className="border-0">Délai</th>
                            <th className="border-0">Montant (€)</th>
                          </>
                        )}
                        {key === '0013' && (
                          <>
                            <th className="border-0">Type de prestation</th>
                            <th className="border-0">Nbre interventions</th>
                            <th className="border-0">Délai</th>
                            <th className="border-0">Montant (€)</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {key === '0020' && articles.map((article, index) => (
                        <tr key={index}>
                          <td>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleRemoveArticleMenage(index)}
                            />
                          </td>
                          <td>{article.nombrePieces}</td>
                          <td>{article.typePiece}</td>
                          <td>{formatDate(article.delai)}</td>
                          <td>{article.montant}</td>
                        </tr>
                      ))}
                      {key === '0013' && articles.map((article, index) => (
                        <tr key={index}>
                          <td>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleRemoveArticleEspaceVert(index)}
                            />
                          </td>
                          <td>{article.TypePrestation}</td>
                          <td>{article.nombreInterventions}</td>
                          <td>{formatDate(article.delai)}</td>
                          <td>{article.montant}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
              </Form.Group>
              <Button className='float-right mt-3' onClick={() => {setCurrentPage(currentPage + 1)}}>Suivant</Button>
            </Card.Body>
            </Card>
            </div>
          );
          case 1:
            return (
              <div>
              <Card>
                <Card.Header>
                  <Card.Title as="h5" className='mx-3 text-center' style={welcomeStyle2}>Distance (Aller-Retour)</Card.Title>
                  <hr class="hr hr-blurry" />
                </Card.Header>
                <Card.Body>
                <Form.Group className="w-100 mb-3">
                    <Form.Label>Nombre de trajets (A/R) :</Form.Label>
                    <Form.Control
                      required
                      value={nombreTrajet}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue >= 0) {
                          setnombreTrajet(newValue);
                        }
                      }}
                      type="number"
                      className="w-100"
                    />
                  </Form.Group>
                <Form.Group className="w-100 mb-3">
                    <Form.Label>Temps de trajet (en min.) :</Form.Label>
                    <Form.Control
                      required
                      value={tempsTrajet}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue >= 0) {
                          settempsTrajet(newValue);
                        }
                      }}
                      type="number"
                      className="w-100"
                    />
                  </Form.Group>
                  <Form.Group className="w-100 mb-3">
                    <Form.Label>Nombre de véhicules :</Form.Label>
                    <Form.Control
                      required
                      value={nombreVehicule}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue >= 0) {
                          setnombreVehicule(newValue);
                        }
                      }}
                      type="number"
                      className="w-100"
                    />
                  </Form.Group>
                  <Form.Group className="w-100 mb-3">
                    <Form.Label>Distance (A/R) :</Form.Label>
                    <Form.Control
                      as="select"
                      value={distance}
                      onChange={handleSelectChangeDistance}
                      className="w-100"
                    >
                      {distanceOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Card.Body>
              </Card>
              <Card>
              <Card.Body>
                <Card.Header>
                  <Card.Title className='mx-3 text-center' as="h5" style={welcomeStyle2}>Produits/Matériel (€)</Card.Title>
                  <hr class="hr hr-blurry" />
                </Card.Header>
                <Form.Group className='w-100'>
                  <Form.Group className='mb-3'>
                    <label>Prix Produits</label>
                    <Form.Control
                      required
                      value={prixProduit}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue >= Number(0)) {
                          setPrixProduit(newValue);
                        }
                      }}
                      type="number"
                    />
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <label>Prix Matériel</label>
                    <Form.Control
                      required
                      value={prixMateriel}
                      onChange={(e) => {
                        const newValue = parseInt(e.target.value, 10);
                        if (!isNaN(newValue) && newValue >= Number(0)) {
                          setPrixMateriel(newValue);
                        }
                      }}
                      type="number"
                    />
                  </Form.Group>
                </Form.Group>
              </Card.Body>
              <Card.Footer className="align-items-center">
                <Button className='float-left' onClick={() => setCurrentPage(currentPage - 1)}>Precedent</Button>
                <Button className='float-right' onClick={() => setCurrentPage(currentPage + 1)}>Suivant</Button>
              </Card.Footer>
            </Card>
              </div>
            );
          case 2:
            return (
              <Card className="mt-3">
              <Card.Header>
                <Card.Title className='text-center' as="h4" style={welcomeStyle2}>Montant Total</Card.Title>
                <hr class="hr hr-blurry" />
              </Card.Header>  
                <Card.Body>
                <div style={{ fontSize: "1.1rem" }}>
                        Temps de travail : {(tempsEV / 60).toFixed(2).toString().split('.')[0]}H{(((parseInt((tempsEV / 60).toFixed(2).toString().split('.')[1])) / 100) * 60).toFixed(0)}M
                        <br></br>Deplacement : {montantTotal.coutKilometres} €
                        <br></br>Produits/Matériels : {isNaN(montantTotal.prixProduits) ? 0 : montantTotal.prixProduits} €
                        <br></br>Coût de revient : {montantTotal.montantTotal} €
                        <div className="d-flex align-items-center">
                          Marge (%) :
                          <Form.Control
                            required
                            value={marge || 0}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                setMarge(newValue);
                              }
                            }}
                            placeholder="Marge (%)"
                            type="number"
                            className="ml-2"
                            style={{ width: '100px', height: '30px' }} // Optionnel : pour contrôler la largeur du champ de saisie
                          />
                        </div>
                        <hr class="hr hr-blurry mt-4" />
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          Montant Total (HT) : {montantTotal.montantTotalHT} €
                        </div>
                      </div>
                  <div className="mt-3 d-flex justify-content-center">
                    <Button onClick={() => setCurrentPage(currentPage - 1)}>Precedent</Button>
                    <Button onClick={handleValidationEspaceVert} className="ml-3">Soumettre</Button>
                  </div>
                </Card.Body>
              </Card>
            );
        default:
          return null;
      }
    }
  };

  const welcomeStyle = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  const welcomeStyle2 = {
    fontFamily: "'Century Gothic', sans-serif",
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  const montantStyle = {
    fontSize: '1.0rem',
    fontWeight: 'bold',
    color: '#113c60',
    marginBottom: '10px',
  };

  return (
    <Container>
      {isMobile ? (
        renderStep()
      ) : (
        <>
          <Row>
            <Col>
              <Card>
              <Card.Header>
                <Card.Title as="h4" style={welcomeStyle}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Création de devis</span>
                    <div style={{ marginLeft: 'auto' }}>
                      <FaQuestionCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleCriteriaPopupShow} />
                      <FaInfoCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleHelpPopupShow} />
                    </div>
                  </div>
                </Card.Title>
                <div>
                  <Modal show={showCriteriaPopup} onHide={handleCriteriaPopupClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Critères de non réalisation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Pour compléter le formulaire de devis de manière correcte et efficace, veuillez vous assurez qu'il n'y a pas de critère de non réalisation avant de commencer votre devis :
                      </p>
                      <p>
                        - Difficultés d'accès 
                      </p>
                      <p>
                        - Si risque de chutes du fait de l'encombrement matériel du terrain
                      </p>
                      <p>
                        - Condition météo (comme neige, verglas)
                      </p>
                      <p>
                        Ces critères sont très importants et doivent impérativement être pris en compte pour la sécurité de nos travailleurs.
                      </p>                    
                    </Modal.Body>
                  </Modal>
                  <Modal show={showHelpPopup} onHide={handleHelpPopupClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Aide</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                      Pour compléter le formulaire de devis de manière correcte et efficace, veuillez suivre les instructions ci-dessous :
                      </p>
                      <p>
                        - Vérifiez les coordonnées 
                      </p>
                      <p>
                        - Renseignez tous les champs
                      </p>
                      <p>
                        - Détaillez les services/produits
                      </p>
                      <p>
                        - Revérifiez les informations
                      </p>
                      <p>
                      Chaque champ est crucial pour la validité du devis : une donnée manquante ou incorrecte son invalidité.
                      </p>
                    </Modal.Body>
                  </Modal>
                </div>
              </Card.Header>
                <Card.Body>
                  <Row className="mb-1">
                    <Col md="4">
                    <Form.Group>
                          <label>
                            Etablissement :
                          </label>
                          <Select
                            value={SelectedEtab}
                            onChange={handleEtabChange}
                            options={etabOptions}
                            placeholder="Choisir une option..."
                            noOptionsMessage={() => "Pas de résultats"}
                          />
                    </Form.Group>
                    </Col>
                    <Col md="4">
                        <Form.Group>
                          <label>
                            Compte client{' '}
                            {/* Icône "i" cliquable pour ouvrir le modal */}
                            {SelectedClient && (
                              <FaInfoCircle
                                style={{ cursor: 'pointer', marginLeft: '8px' }}
                                onClick={handleModalOpen}
                              />
                            )}
                          </label>
                          <Select
                            value={SelectedClient}
                            onChange={handleClientChange}
                            options={clientOptions}
                            placeholder="Choisir une option..."
                            noOptionsMessage={() => "Pas de résultats"}
                          />
                        </Form.Group>
                      </Col>
                      <Modal show={showModal} onHide={handleModalClose}>
                        <Modal.Header closeButton>
                          {/* ... */}
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center' }}>
                          {/* Affichage conditionnel du nom du client ou message si aucun client n'est sélectionné */}
                          {clientInfo ? (
                            <>
                              {/* Nom du client en gras et majuscules */}
                              <h5 style={{ fontWeight: 'bold', textTransform: 'uppercase', marginBottom: '1rem' }}>
                                {clientInfo.nom}
                              </h5>

                              {/* Affichage des autres informations alignées à droite */}
                              <div style={{ textAlign: 'left' }}>
                                {clientInfo.rue1 && <div>{clientInfo.rue1}</div>}
                                {clientInfo.rue2 && <div>{clientInfo.rue2}</div>}
                                {clientInfo.rue3 && <div>{clientInfo.rue3}</div>}
                                {clientInfo.ville && <div>{clientInfo.ville}</div>}
                                {clientInfo.cp && <div>{clientInfo.cp}</div>}
                                {clientInfo.bp && <div>BP : {clientInfo.bp}</div>}
                                {clientInfo.pays && <div>{clientInfo.pays}</div>}
                              </div>
                            </>
                          ) : (
                            // Message à afficher lorsque aucun client n'est sélectionné
                            <h5 style={{ fontWeight: 'bold', color: 'red' }}>
                              Aucun client n'a été sélectionné
                            </h5>
                          )}
                        </Modal.Body>
                      </Modal>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>N° Document</Form.Label>
                        <Form.Control
                          required
                          value={String(numeroDocument).padStart(6, '0')} // Format en 6 chiffres
                          type="number"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Nav variant="tabs" activeKey={key} onSelect={(k) => { setKey(k); setCurrentPage(0); }} className="mb-3">
                    <Nav.Item>
                      <Nav.Link eventKey="0020">Menage</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="0013">Espace Vert</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  {key === '0020' && (
                    <Form>
                      <Row className="mb-1">
                        <Col md="3" xs="12">
                        <Form.Group>
                        <div style={{ display: 'flex', marginTop : '5px', alignItems: 'autocenter'}}>
                          <label>
                            Type de pièce :
                          </label>
                          {!showInput &&<div style={{ marginLeft: '5px', marginTop: '-5px', cursor:'pointer'}}>
                            <img
                              alt="Card cap"
                              src={changeTextLogo}
                              onClick={handleClickInput}
                              width="16px"
                              height="16px"
                              />
                          </div>}
                          {showInput &&<div style={{ marginLeft: '5px', marginTop: '-5px', cursor:'pointer'}}>
                            <img
                              alt="Card cap"
                              src={cancelLogo}
                              onClick={handleClickInput}
                              width="16px"
                              height="16px"
                              />
                          </div>}
                        </div>
                        <Select
                          value={SelectedArticle}
                          onChange={handleArticleChange}
                          options={articleOptions}
                          placeholder="Choisir une option..."
                          noOptionsMessage={() => "Pas de résultats"}
                          />
                          </Form.Group>
                        </Col>
                        {showInput &&
                        <Col md="3" xs="6">
                        <Form.Group>
                          <label>Libellé :</label>
                          <Form.Control
                            type="text"
                            value={wording}
                            onChange={(inputValue) => {
                              setWording(inputValue.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>}
                        <Col md="2" xs="6">
                          <Form.Group>
                            <label>Nombre de pièces</label>
                            <Form.Control
                              value={nombrePiecesMenage}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 0) { setNombrePiecesMenage(newValue); } }}
                              placeholder="Ex : 3"
                              type="number"
                            />
                          </Form.Group>
                        </Col>
                        {showInput &&
                        <Col md="2" xs="6">
                          <Form.Group>
                            <label>Nombre de passages</label>
                            <Form.Control
                              value={nombrePassagesMenage}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 0) { setNombrePassagesMenage(newValue); } }}
                              placeholder="Ex : 1"
                              type="number"
                            />
                          </Form.Group>
                        </Col>}
                        {!showInput &&
                        <Col md="3" xs="6">
                          <Form.Group>
                            <label>Nombre de passages</label>
                            <Form.Control
                              value={nombrePassagesMenage}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 0) { setNombrePassagesMenage(newValue); } }}
                              placeholder="Ex : 1"
                              type="number"
                            />
                          </Form.Group>
                        </Col>}
                        {showInput &&
                        <Col md="2" xs="6">
                          <Form.Group>
                            <label>Superficie / pièce (m²)</label>
                            <Form.Control
                              value={superficieMenage}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 0) { setSuperficieMenage(newValue); } }}
                              placeholder="Ex : 1"
                              type="number"
                              disabled={isDisabledSuperficie}
                            />
                          </Form.Group>
                        </Col>}
                        {!showInput &&
                        <Col md="3" xs="6">
                          <Form.Group>
                            <label>Superficie / pièce (m²)</label>
                            <Form.Control
                              value={superficieMenage}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 0) { setSuperficieMenage(newValue); } }}
                              placeholder="Ex : 1"
                              type="number"
                              disabled={isDisabledSuperficie}
                            />
                          </Form.Group>
                        </Col>}
                      </Row>
                      <Row className="mb-3">
                        <Col md="3" xs="12">
                        <Form.Group>
                          <Form.Label>Date de fin de prestation :</Form.Label>
                          <Form.Control
                            required
                            type="date"
                            value={delai}
                            onChange={(e) => setDelai(e.target.value)}
                            min={today} // Empêche la sélection des dates antérieures à aujourd'hui
                            placeholder="JJ/MM/AAAA"
                          />
                        </Form.Group>
                        </Col>
                        <Col md="6" xs="8">
                        <Form.Group>
                          <label>
                            Spécificités :
                          </label>
                          <Select
                            value={SelectedSpecif}
                            onChange={handleSpecifChange}//setSpecif([e.value.split(',')[0], e.value.split(',')[1], e.value.split(',')[2], e.value.split(',')[3]])}
                            options={specifOptions}
                            placeholder="Choisir une option..."
                            noOptionsMessage={() => "Pas de résultats"}
                          />
                        </Form.Group>
                        </Col>
                        <Col md="3" className="d-flex align-items-end justify-content-end">
                          <Button className="btn-fill" type="button" variant="primary" onClick={handleAddSpecif}>
                            <FaPlus />
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col className="d-flex justify-content-end">
                          <Button className="btn-fill mr-1" type="button" variant="info" onClick={handleSubmitArticleMenage}> Ajouter Article </Button>
                        </Col>
                      </Row>
                      <div className="clearfix"></div>
                    </Form>
                  )}
                  {key === '0013' && (
                    <Form>
                      <Row className="mb-1">
                        <Col md="3" xs="12">
                        <Form.Group>
                          <label>
                            Type de prestation :
                          </label>
                        <Select
                          value={SelectedArticle}
                          onChange={handleArticleChange}
                          options={articleOptions}
                          placeholder="Choisir une option..."
                          noOptionsMessage={() => "Pas de résultats"}
                        />
                          </Form.Group>
                        </Col>
                        <Col md="2" xs="6">
                          <Form.Group>
                            <label>Valeur au réel (€)</label>
                            <Form.Control
                              value={prestationExterne}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 0) { setPrestationExterne(newValue); } }}
                              placeholder="Ex : 1"
                              type="number"
                              disabled={isDisabledReal}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="3" xs="6">
                          <Form.Group>
                            <label>Nombre d'intervention</label>
                            <Form.Control
                              value={nombreInterventionsEspaceVert}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 0) { setNombreInterventionsEspaceVert(newValue); } }}
                              placeholder="Ex : 3"
                              type="number"
                              disabled={isDisabledIntervention}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="3" xs="6">
                          <Form.Group>
                            <label>Superficie (m²)</label>
                            <Form.Control
                              value={superficieEspaceVert}
                              onChange={(e) => { const newValue = parseInt(e.target.value, 10); if (!isNaN(newValue) && newValue >= 0) { setSuperficieEspaceVert(newValue); } }}
                              placeholder="Ex : 1"
                              type="number"
                              disabled={isDisabledSuperficie}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="3" xs="12">
                        <Form.Group>
                          <Form.Label>Date de fin de prestation :</Form.Label>
                          <Form.Control
                            required
                            type="date"
                            value={delai}
                            onChange={(e) => setDelai(e.target.value)}
                            min={today} // Empêche la sélection des dates antérieures à aujourd'hui
                            placeholder="JJ/MM/AAAA"
                          />
                        </Form.Group>
                        </Col>
                        <Col md="6" xs="8">
                        <Form.Group>
                          <label>
                            Spécificités :
                          </label>
                        <Select
                          value={SelectedSpecif}
                            onChange={handleSpecifChange}//setSpecif([e.value.split(',')[0], e.value.split(',')[1], e.value.split(',')[2], e.value.split(',')[3]])}
                            options={specifOptions}
                            placeholder="Choisir une option..."
                            noOptionsMessage={() => "Pas de résultats"}
                        />
                        </Form.Group>
                        </Col>
                        <Col md="3" className="d-flex align-items-end justify-content-end">
                          <Button className="btn-fill" type="button" variant="primary" onClick={handleAddSpecif}>
                            <FaPlus />
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col className="d-flex justify-content-end">
                          <Button className="btn-fill mr-1" type="button" variant="info" onClick={handleSubmitArticleEspaceVert}> Ajouter Article </Button>
                        </Col>
                      </Row>
                      <div className="clearfix"></div>
                    </Form>
                  )}
                </Card.Body>
              </Card>
            </Col>
            </Row>
            <Row>
            <Col md="12">
            {key === '0020' && (
                <>
                <Row>
                  <Col md="8">
                    <Card>
                      <Card.Header>
                      <div style={{ display: 'flex', alignItems: 'autocenter'}}>
                      <div style={{ marginRight: 'auto' }}>
                        <img
                          alt="Card cap"
                          src={distanceLogo}
                          width="35px"
                          height="35px"
                        />
                      </div>
                      <div style={{ marginRight: 'auto' }}>
                      <Card.Title as="h5" className='text-center' style={welcomeStyle2}>Distance (Aller-Retour)</Card.Title>
                      </div>
                    </div>
                  <hr class="hr hr-blurry" />
                      </Card.Header>
                      <Card.Body className="d-flex align-items-center">
                      <Form.Group className="w-100">
                        <label>Nombre de trajets (A/R) :</label>
                          <Form.Control
                            required
                            value={nombreTrajet}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                setnombreTrajet(newValue);
                              }
                            }}
                            type="number"
                          />
                        </Form.Group>
                        <Form.Group className="w-100 ml-2">
                        <label>Temps de trajet (en min.) :</label>
                          <Form.Control
                            required
                            value={tempsTrajet}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                settempsTrajet(newValue);
                              }
                            }}
                            type="number"
                          />
                        </Form.Group>
                        <Form.Group className="w-100 ml-2">
                        <label>Nombre de véhicules :</label>
                          <Form.Control
                            required
                            value={nombreVehicule}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                setnombreVehicule(newValue);
                              }
                            }}
                            type="number"
                          />
                        </Form.Group>
                        <Form.Group className="w-100 ml-2">
                          <label>Distance (A/R) :</label>
                          <Form.Control
                            as="select"
                            value={distance}
                            onChange={handleSelectChangeDistance}
                          >
                            {distanceOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md="4">
                  <Card>
                  <Card.Header>
                      <div style={{ display: 'flex', alignItems: 'autocenter'}}>
                      <div style={{ marginRight: 'auto' }}>
                        <img
                          alt="Card cap"
                          src={productLogo}
                          width="35px"
                          height="35px"
                        />
                      </div>
                      <div style={{ marginRight: 'auto' }}>
                      <Card.Title as="h5" className='text-center' style={welcomeStyle2}>Produits/Matériel (€)</Card.Title>
                      </div>
                    </div>
                  <hr class="hr hr-blurry" />
                      </Card.Header>
                    <Card.Body className="d-flex align-items-center">
                        <Form.Group className="w-100">
                          <label>Prix Produits</label>
                          <Form.Control
                            required
                            value={prixProduit}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= Number(0)) {
                                setPrixProduit(newValue);
                              }
                            }}
                            type="number"
                          />
                        </Form.Group>
                        <Form.Group className="w-100 ml-2">
                          <label>Prix Matériel</label>
                          <Form.Control
                            required
                            value={prixMateriel}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= Number(0)) {
                                setPrixMateriel(newValue);
                              }
                            }}
                            type="number"
                          />
                        </Form.Group>
                    </Card.Body>
                  </Card>
                  </Col>
                </Row>
                </>
              )}  
              {key === '0013' && (
                <>
                <Row>
                  <Col md="8">
                  <Card>
                  <Card.Header>
                      <div style={{ display: 'flex', alignItems: 'autocenter'}}>
                      <div style={{ marginRight: 'auto' }}>
                        <img
                          alt="Card cap"
                          src={distanceLogo}
                          width="35px"
                          height="35px"
                        />
                      </div>
                      <div style={{ marginRight: 'auto' }}>
                      <Card.Title as="h5" className='text-center' style={welcomeStyle2}>Distance (Aller-Retour)</Card.Title>
                      </div>
                    </div>
                  <hr class="hr hr-blurry" />
                      </Card.Header>
                      <Card.Body className="d-flex align-items-center">
                      <Form.Group className="w-100">
                        <label>Nombre de trajets (A/R) :</label>
                          <Form.Control
                            required
                            value={nombreTrajet}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                setnombreTrajet(newValue);
                              }
                            }}
                            type="number"
                          />
                        </Form.Group>
                        <Form.Group className="w-100 ml-2">
                        <label>Temps de trajet (en min.) :</label>
                          <Form.Control
                            required
                            value={tempsTrajet}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                settempsTrajet(newValue);
                              }
                            }}
                            type="number"
                          />
                        </Form.Group>
                        <Form.Group className="w-100 ml-2">
                        <label>Nombre de véhicules :</label>
                          <Form.Control
                            required
                            value={nombreVehicule}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              if (!isNaN(newValue) && newValue >= 0) {
                                setnombreVehicule(newValue);
                              }
                            }}
                            type="number"
                          />
                        </Form.Group>
                        <Form.Group className="w-100 ml-2">
                          <label>Distance (A/R) :</label>
                          <Form.Control
                            as="select"
                            value={distance}
                            onChange={handleSelectChangeDistance}
                          >
                            {distanceOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card>
                    <Card.Header>
                      <div style={{ display: 'flex', alignItems: 'autocenter'}}>
                      <div style={{ marginRight: 'auto' }}>
                        <img
                          alt="Card cap"
                          src={productLogo}
                          width="35px"
                          height="35px"
                        />
                      </div>
                      <div style={{ marginRight: 'auto' }}>
                      <Card.Title as="h5" className='text-center' style={welcomeStyle2}>Produits/Matériel (€)</Card.Title>
                      </div>
                    </div>
                  <hr class="hr hr-blurry" />
                      </Card.Header>
                      <Card.Body className="d-flex align-items-center">
                          <Form.Group className="w-100">
                            <label>Prix Produits</label>
                            <Form.Control
                              required
                              value={prixProduit}
                              onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                if (!isNaN(newValue) && newValue >= Number(0)) {
                                  setPrixProduit(newValue);
                                }
                              }}
                              placeholder="Produit (€)"
                              type="number"
                            />
                          </Form.Group>
                          <Form.Group className="w-100 ml-2">
                            <label>Prix Matériel</label>
                            <Form.Control
                              required
                              value={prixMateriel}
                              onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                if (!isNaN(newValue) && newValue >= Number(0)) {
                                  setPrixMateriel(newValue);
                                }
                              }}
                              placeholder="Matériel (€)"
                              type="number"
                            />
                          </Form.Group>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="8">
              <Card>
              <Card.Header>
                      <div style={{ display: 'flex', alignItems: 'autocenter'}}>
                      <div style={{ marginRight: 'auto' }}>
                        <img
                          alt="Card cap"
                          src={listLogo}
                          width="32px"
                          height="32px"
                        />
                      </div>
                      <div style={{ marginRight: 'auto' }}>
                      <Card.Title as="h5" className='text-center' style={welcomeStyle2}>Liste des articles</Card.Title>
                      </div>
                    </div>
                  <hr class="hr hr-blurry" />
                      </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0"></th>
                        {key === '0020' && (
                          <>
                            <th className="border-0">Type de pièce</th>
                            <th className="border-0">Pièces</th>
                            <th className="border-0">Passages</th>
                            <th className="border-0">Superficie</th>
                            <th className="border-0">Specificites</th>
                            <th className="border-0">Délai</th>
                            <th className="border-0">Montant (€)</th>
                          </>
                        )}
                        {key === '0013' && (
                          <>
                            <th className="border-0">Type de prestation</th>
                            <th className="border-0">interventions</th>
                            <th className="border-0">Superficie</th>
                            <th className="border-0">Specificites</th>
                            <th className="border-0">Délai</th>
                            <th className="border-0">Montant (€)</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {key === '0020' && articles.map((article, index) => (
                        <tr key={index}>
                          <td>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleRemoveArticleMenage(index)}
                            />
                          </td>
                          <td>{article.typePiece}</td>
                          <td>{article.nombrePieces}</td>
                          <td>{article.nombrePassages}</td>
                          <td>{article.superficie}</td>
                          <td>{article.specificites.join(", ")}</td>
                          <td>{formatDate(article.delai)}</td>
                          <td>{article.montant}</td>
                        </tr>
                      ))}
                      {key === '0013' && articles.map((article, index) => (
                        <tr key={index}>
                          <td>
                            <FaTrash
                              style={{ cursor: 'pointer', color: 'red' }}
                              onClick={() => handleRemoveArticleEspaceVert(index)}
                            />
                          </td>
                          <td>{article.TypePrestation}</td>
                          <td>{article.nombreInterventions}</td>
                          <td>{article.superficieEV}</td>
                          <td>{article.specificites.join(", ")}</td>
                          <td>{formatDate(article.delai)}</td>
                          <td>{article.montant}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            {key === '0013' &&  (
              <>
              <Col md="4">
                <Card>
                <Card.Header>
                      <div style={{ display: 'flex', alignItems: 'autocenter'}}>
                      <div style={{ marginRight: 'auto' }}>
                        <img
                          alt="Card cap"
                          src={amountLogo}
                          width="35px"
                          height="35px"
                        />
                      </div>
                      <div style={{ marginRight: 'auto' }}>
                      <Card.Title as="h5" className='text-center' style={welcomeStyle2}>Montant Total</Card.Title>
                      </div>
                    </div>
                  <hr class="hr hr-blurry" />
                  </Card.Header>
                  <Card.Body>
                    <div style={{ fontSize: "1.1rem" }}>
                    Temps de travail : {(tempsEV / 60).toFixed(2).toString().split('.')[0]}H{(((parseInt((tempsEV / 60).toFixed(2).toString().split('.')[1])) / 100) * 60).toFixed(0)}M
                    <br></br>Deplacement : {montantTotal.coutKilometres} €
                    <br></br>Produits/Matériels : {isNaN(montantTotal.prixProduits) ? 0 : montantTotal.prixProduits} €
                    <br></br>Coût de revient : {montantTotal.montantTotal} €
                      <div className="d-flex align-items-center">
                        Marge (%) :
                        <Form.Control
                          required
                          value={marge || 0}
                          onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10);
                            if (!isNaN(newValue) && newValue >= 0) {
                              setMarge(newValue);
                            }
                          }}
                          placeholder="Marge (%)"
                          type="number"
                          className="ml-2"
                          style={{ width: '100px', height: '30px' }} // Optionnel : pour contrôler la largeur du champ de saisie
                        />
                      </div>
                      <hr class="hr hr-blurry mt-4" />
                      <div className="d-flex justify-content-between align-items-center">
                        Montant Total (HT) : {montantTotal.montantTotalHT} €
                        <Button className="btn-fill" type="button" variant="primary" onClick={handleValidationMenage}>
                          <FaCheck />
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              </>
            )}
            {key === '0020' &&  (
              <>
              <Col md="4">
                <Card>
                <Card.Header>
                      <div style={{ display: 'flex', alignItems: 'autocenter'}}>
                      <div style={{ marginRight: 'auto' }}>
                        <img
                          alt="Card cap"
                          src={amountLogo}
                          width="35px"
                          height="35px"
                        />
                      </div>
                      <div style={{ marginRight: 'auto' }}>
                      <Card.Title as="h5" className='text-center' style={welcomeStyle2}>Montant Total</Card.Title>
                      </div>
                    </div>
                  <hr class="hr hr-blurry" />
                  </Card.Header>  
                  <Card.Body>
                    <div style={{ fontSize: "1.1rem" }}>
                    Temps de travail : {(tempsMenage).toFixed(2).toString().split('.')[0]}H{(((parseInt((tempsMenage).toFixed(2).toString().split('.')[1])) / 100) * 60).toFixed(0)}M
                    <br></br>Deplacement : {montantTotal.coutKilometres} €
                    <br></br>Produits/Matériels : {isNaN(montantTotal.prixProduits) ? 0 : montantTotal.prixProduits} €
                    <br></br>Coût de revient : {montantTotal.montantTotal} €
                      <div className="d-flex align-items-center">
                        Marge (%) :
                        <Form.Control
                          required
                          value={marge || 0}
                          onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10);
                            if (!isNaN(newValue) && newValue >= 0) {
                              setMarge(newValue);
                            }
                          }}
                          placeholder="Marge (%)"
                          type="number"
                          className="ml-2"
                          style={{ width: '100px', height: '30px' }} // Optionnel : pour contrôler la largeur du champ de saisie
                        />
                      </div>
                      <hr class="hr hr-blurry mt-4" />
                      <div className="d-flex justify-content-between align-items-center">
                        Montant Total (HT) : {montantTotal.montantTotalHT} €
                        <Button className="btn-fill" type="button" variant="primary" onClick={handleValidationMenage}>
                          <FaCheck />
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              </>
            )}
          </Row>
        </>
      )}
    </Container>
  );
}  

export default Devis;
