import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Table, Nav, Card, Form, Button, Modal } from 'react-bootstrap';
import { AuthContext } from '../components/LoginPage/LoginPage.js';
import { FaTrash, FaPlus } from "react-icons/fa";
import useWindowSize from '../assets/config/WindowSize.js';
import { FaQuestionCircle, FaInfoCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';

function Articles() {
  const [newArticle, setNewArticle] = useState({
    id: "",
    soc:"",
    code: "",
    title: "",
    title2: "",
    service: "",
    company: "",
    number: 0,
    unitMesure: "",
    unitTemps: "",
    unitPrice: "",
    codeTVA: "",
    specifications: [], // Initialize as an empty array
    codeActivite: "", 
    coutRevient: "" 
  });

  const [initialArticle, setInitialArticle] = useState({
    id: "",
    soc: "",
    code: "",
    title: "",
    title2: "",
    service: "",
    company: "",
    number: 0,
    unitMesure: "",
    unitTemps: "",
    unitPrice: "",
    codeTVA: "",
    specifications: [],
    codeActivite: "",
    coutRevient: ""
  });
  
  const [isModified, setIsModified] = useState(false);
  const location = useLocation();
  const size = useWindowSize();
  const isMobile = size.width <= 768;
  const articleDetails = location.state && location.state.articleDetails;
  const {userDetails} = useContext(AuthContext);
  const [etablissements, setEtablissements] = useState({});
  const [selectedEtab, setSelectedEtab] = useState("");
  const [articles, setArticles] = useState([]);
  const [unitTempsOptions, setUnitTempsOptions] = useState([]);
  const [unitMesureOptions, setUnitMesureOptions] = useState([]);
  const [tempCode, setTempCode] = useState(''); 
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const handleHelpPopupShow = () => setShowHelpPopup(true);
  const handleHelpPopupClose = () => setShowHelpPopup(false);
  
  const [showSpecificationModal, setShowSpecificationModal] = useState(false);
  const [newSpecification, setNewSpecification] = useState({
    code: "",
    designation1: "",
    designation2: "",
    majoration: "",
    isManual: false,
  });

  useEffect(() => {
    if (userDetails?.mail) {
      axios.get(`/etab_by_mail?email=${userDetails.mail}`)
        .then(response => {
          const etabData = {};
          response.data.forEach(etab => {
            etabData[etab.etab_soc] = { etab_nom: etab.etab_nom };
          });
          setEtablissements(etabData);
          if (response.data.length > 0) {
            setSelectedEtab(response.data[0].etab_soc); 
          }
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [userDetails.mail]);

  
    // Deuxième useEffect pour mettre à jour le code temporaire
    useEffect(() => {
      if (articleDetails) {
        setTempCode(articleDetails.codeart);  // Utilisez le code de l'article ici
        setNewArticle(prev => ({
          ...prev,
          code: articleDetails.codeart,
          isCustom: false, // Exemple d'une propriété que vous pourriez avoir
        }));
      }
    }, [articleDetails]);
    

    useEffect(() => {
      const fetchArticles = async () => {
        try {
          const response = await axios.get(`/article?soc=${selectedEtab}`);
          const articlesList = response.data.map(article => ({
            value: article.codeart, 
            label: article.codeart, 
          }));
          setArticles(articlesList); 
        } catch (error) {
          console.error('Erreur lors de la récupération des articles :', error);
        }
      };
    
      if (selectedEtab) { 
        fetchArticles();
      }
    }, [selectedEtab]); 

    useEffect(() => {
      if (location.state && location.state.articleCode) {
        setNewArticle(prev => ({
          ...prev,
          code: location.state.articleCode,
          isCustom: false, // Exemple d'une propriété que vous pourriez avoir
        }));
      }
    }, [location.state]);

    useEffect(() => {
      if (newArticle.code && selectedEtab) {
        const fetchArticleDetails = async (code, soc) => {
          try {
            const response = await axios.get(`/article_details?code=${code}&soc=${soc}`);
            const articleDetails = response.data;
    
            // Mettre à jour les valeurs de l'article avec les détails récupérés
            setNewArticle((prevArticle) => ({
              ...prevArticle,
              id: articleDetails.id,
              code: articleDetails.code,
              title: articleDetails.title,
              title2: articleDetails.title2,
              service: articleDetails.service,
              number: articleDetails.number,
              unitMesure: articleDetails.unitMesure, // Mettre à jour la valeur de l'unité de mesure
              unitTemps: articleDetails.unitTemps,
              unitPrice: articleDetails.unitPrice,
              codeTVA: articleDetails.codeTVA,
              coutRevient: articleDetails.coutRevient,
              codeActivite: articleDetails.codeActivite,
              specifications: articleDetails.specifications || [],
            }));
    
            // Mettre à jour les valeurs initiales une fois les détails récupérés
            setInitialArticle({
              id: articleDetails.id,
              code: articleDetails.code,
              title: articleDetails.title,
              title2: articleDetails.title2,
              service: articleDetails.service,
              number: articleDetails.number,
              unitMesure: articleDetails.unitMesure, // Mettre à jour la valeur de l'unité de mesure
              unitTemps: articleDetails.unitTemps,
              unitPrice: articleDetails.unitPrice,
              codeTVA: articleDetails.codeTVA,
              coutRevient: articleDetails.coutRevient,
              codeActivite: articleDetails.codeActivite,
              specifications: articleDetails.specifications || [],
            });
    
            setIsModified(false); // Reset the isModified state after loading new article details
          } catch (error) {
            console.error('Erreur lors de la récupération des détails de l\'article :', error);
          }
        };
        fetchArticleDetails(newArticle.code, selectedEtab);
      }
    }, [newArticle.code, selectedEtab]);  
    

    useEffect(() => {
      if (newArticle.codeActivite === "0013") {
        setNewArticle(prevArticle => ({
          ...prevArticle,
          service: "Espace Vert"
        }));
      } else if (newArticle.codeActivite === "0020") {
        setNewArticle(prevArticle => ({
          ...prevArticle,
          service: "Ménage"
        }));
      }
    }, [newArticle.codeActivite]); // Exécuter chaque fois que le code d'activité change

    useEffect(() => {
      const fetchUnitTemps = async () => {
        try {
          const response = await axios.get('/temps');
          const tempsOptions = response.data.map(temps => ({
            value: temps.unitTemps,
            label: temps.unitTemps,
          }));
          setUnitTempsOptions(tempsOptions);
        } catch (error) {
          console.error('Erreur lors de la récupération des unités de temps :', error);
        }
      };

      fetchUnitTemps();
    }, [selectedEtab, newArticle.code]);

    useEffect(() => {
      const fetchUnitMesure = async () => {
        try {
          const response = await axios.get('/mesure');
          const mesureOptions = response.data.map(mesure => ({
            value: mesure.unitMesure,
            label: mesure.unitMesure,
          }));
          setUnitMesureOptions(mesureOptions);
        } catch (error) {
          console.error('Erreur lors de la récupération des unités de mesure :', error);
        }
      };
    
      fetchUnitMesure();
    }, [selectedEtab, newArticle.code]);
    
    useEffect(() => {
      const fetchSpecificites = async () => {
        try {
          const response = await axios.get(`/specificites?soc=${selectedEtab}&code=${newArticle.code}`);
          const specificitesList = response.data;
          // Mettre à jour l'état avec les spécificités reçues
          setNewArticle((prevArticle) => ({
            ...prevArticle,
            specifications: specificitesList.map(specificite => ({
              code: specificite.codeSpecif,
              designation1: specificite.libelle1,
              designation2: specificite.libelle2,
              majoration: specificite.majoration,
              isManual: specificite.isManual,
            })),
          }));
        } catch (error) {
          console.error('Erreur lors de la récupération des spécificités :', error);
        }
      };
    
      if (selectedEtab && newArticle.code) {
        fetchSpecificites();
      }
    }, [selectedEtab, newArticle.code]);

    const handleEtabChange = (e) => {
      setSelectedEtab(e.target.value);
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setIsModified(value !== initialArticle[name]);
    
      setNewArticle({ ...newArticle, [name]: value });
    };
    
    const isFormEmpty = () => {
      return Object.values(newArticle).every(value => value === "" || value === 0 || value === null);
    };

    const handleAddSpecification = () => {
      setShowSpecificationModal(true);
    };

    const handleRemoveSpecification = (index) => {
      const updatedSpecifications = newArticle.specifications.filter((_, i) => i !== index);
      setNewArticle({
        ...newArticle,
        specifications: updatedSpecifications,
      });
      setIsModified(JSON.stringify(updatedSpecifications) !== JSON.stringify(initialArticle.specifications));
    };

    const handleSaveSpecification = () => {
      if (newSpecification.code && newSpecification.designation1) {
        setNewArticle({
          ...newArticle,
          specifications: [
            ...newArticle.specifications,
            { 
              code: newSpecification.code, 
              designation1: newSpecification.designation1, 
              designation2: newSpecification.designation2, 
              majoration: newSpecification.majoration ,
              isManual: newSpecification.isManual, 
            },
          ],
        });
        setNewSpecification({ code: "", designation1: "", designation2: "", majoration: "" }); // Reset input fields
        setShowSpecificationModal(false);
      }
    };

    const handleAddNewArticle = async () => {
      if (isModified && !isFormEmpty()) {
        try {
          // Determine whether to create or update based on the presence of an ID or code
          const endpoint = newArticle.id ? '/update_article' : '/create_article';
          const response = await axios.post(endpoint, {
            soc: selectedEtab,
            code: newArticle.code,
            title: newArticle.title,
            title2: newArticle.title2,
            codeActivite: newArticle.codeActivite,
            number: newArticle.number,
            unitMesure: newArticle.unitMesure,
            unitTemps: newArticle.unitTemps,
            coutRevient: newArticle.coutRevient,
            codeTVA: newArticle.codeTVA,
            specifications: newArticle.specifications
          });
          
          console.log('Article opération réussie :', response.data);
          
          // Réinitialiser le formulaire après l'ajout ou la mise à jour de l'article
          setNewArticle({
            code: "",
            title: "",
            title2: "",
            service: "Menage",
            company: "",
            number: 0,
            unitMesure: "",
            unitTemps: "",
            unitPrice: "",
            codeTVA: "",
            specifications: [],
            codeActivite: "",
            coutRevient: ""
          });
        } catch (error) {
          console.error('Erreur lors de l\'opération sur l\'article :', error);
        }
      }
    };
    

    const welcomeStyle = {
      fontFamily: "'Century Gothic', sans-serif",
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#113c60',
      marginBottom: '10px',
    };

    const welcomeStyle2 = {
      fontFamily: "'Century Gothic', sans-serif",
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#113c60',
    };

  const handleClearForm = () => {
    setNewArticle({
      code: '',
      title: '',
      title2: '',
      service: '',
      company: '',
      number: 0,
      unitMesure: '',
      unitTemps: '',
      unitPrice: '',
      codeTVA: '',
      specifications: [],
      codeActivite: '',
      coutRevient: ''
    });
    setInitialArticle({});
    setIsModified(false);
    setSelectedEtab("");
    setTempCode("");
    setNewSpecification({
      code: "",
      designation1: "",
      designation2: "",
      majoration: "",
      isManual: false,
    });
    setShowSpecificationModal(false);
    setShowHelpPopup(false);
  };

    const renderStep = () => {
      return (
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h4" style={welcomeStyle}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>Création d'articles</span>
                      <div style={{ marginLeft: 'auto' }}>
                        <FaInfoCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleHelpPopupShow} />
                      </div>
                    </div>
                  </Card.Title>
                  <div>
                    <Modal show={showHelpPopup} onHide={handleHelpPopupClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Aide</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                        Pour compléter le formulaire de devis de manière correcte et efficace, veuillez suivre les instructions ci-dessous :
                        </p>
                        <p>
                          - Vérifiez les coordonnées 
                        </p>
                        <p>
                          - Renseignez tous les champs
                        </p>
                        <p>
                          - Détaillez les services/produits
                        </p>
                        <p>
                          - Revérifiez les informations
                        </p>
                        <p>
                        Chaque champ est crucial pour la validité du devis. Une donnée manquante ou incorrecte peut entraîner des retards et des complications. Merci de votre coopération.
                        </p>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label>Etablissement</label>
                          <Form.Control
                            as="select"
                            value={selectedEtab}
                            onChange={handleEtabChange}
                          >
                            <option value="">Sélectionner un établissement</option>
                            {Object.keys(etablissements).map((key) => (
                              <option key={key} value={key}>{key + " - " + etablissements[key].etab_nom}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group>
                          <label>Code Article</label>
                          <div style={{ position: 'relative' }}>
                            {!newArticle.isCustom && (
                              <Select
                                placeholder="Choisir un article..."
                                options={articles} 
                                value={articles.find(article => article.value === newArticle.code)} 
                                onChange={(selectedOption) => {
                                  if (selectedOption) {
                                    setNewArticle({ ...newArticle, code: selectedOption.value, isCustom: false });
                                  } else {
                                    handleClearForm();
                                  }
                                }}
                                onInputChange={(inputValue) => {
                                  setTempCode(inputValue); 
                                }}
                                isClearable={true}
                                noOptionsMessage={() => "Il n'y a pas d'article sous ce code"} 
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    borderColor: '#ced4da', 
                                    boxShadow: 'none',
                                    backgroundColor: '#fff',
                                    padding: '0',
                                    height: '40px',
                                    borderRadius: '4px', 
                                  }),
                                }}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    setNewArticle({ ...newArticle, code: tempCode, isCustom: true }); // Sauvegarder la valeur temporaire dans l'input
                                  }
                                }}
                                onBlur={() => {
                                  if (!articles.some(article => article.label.toLowerCase().includes(tempCode.toLowerCase()))) {
                                    setNewArticle({ ...newArticle, code: tempCode, isCustom: true }); // Sauvegarder la valeur temporaire dans l'input
                                  }
                                }}
                              />
                            )}
                            {newArticle.isCustom && (
                              <Form.Control
                                type="text"
                                name="code"
                                value={newArticle.code}
                                onChange={(e) => {
                                  if (e.target.value === '') {
                                    setNewArticle({ ...newArticle, code: '', isCustom: false });
                                  } else {
                                    setNewArticle({ ...newArticle, code: e.target.value });
                                  }
                                }}
                                placeholder="Entrer un code d'article..."
                                style={{
                                  width: '100%',
                                  height: '40px',
                                  padding: '0 12px', // Centrage du texte en ajoutant du padding
                                  border: '1px solid #ced4da', // Remet le contour
                                  backgroundColor: '#fff',
                                  textAlign: 'left', // Align the input text to the left
                                  borderRadius: '4px', // Coin arrondi standard pour correspondre au Select
                                }}
                              />
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Form.Group>
                          <label>Désignation</label>
                          <Form.Control
                            type="text"
                            name="title"
                            value={newArticle.title}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>Désignation 2</label>
                          <Form.Control
                            type="text"
                            name="title2"
                            value={newArticle.title2}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>Code Activité</label>
                          <Form.Control
                            type="number"
                            name="codeActivite"
                            value={newArticle.codeActivite}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>Activité</label>
                          <Form.Control
                            as="select"
                            name="service"
                            value={newArticle.service}
                            onChange={handleInputChange}
                            disabled={newArticle.codeActivite === "0013" || newArticle.codeActivite === "0020"} // Grise le champ si le code d'activité est 0013 ou 0020
                          >
                            <option value="Menage">Ménage</option>
                            <option value="Espace Vert">Espace Vert</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Form>
                    <Row className="align-items-center">
                      <Col md="2">
                        <Form.Group>
                          <label>Nombre</label>
                          <Form.Control
                            type="number"
                            name="number"
                            value={newArticle.number}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>

                      {/* Nouvelle ligne pour Unité de mesure, / et Unité de temps */}
                      <Col md="6">
                        <Row className="align-items-center">
                          <Col xs="5">
                            <Form.Group>
                              <label>Unité de mesure</label>
                              <Form.Control
                                as="select"
                                name="unitMesure"
                                value={newArticle.unitMesure}
                                onChange={handleInputChange}
                              >
                                <option value="">Sélectionner une unité de mesure</option>
                                {unitMesureOptions.map(option => (
                                  <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          
                          <Col xs="1" className="text-center">
                            <span style={{ 
                            fontWeight: 'bold', 
                            fontSize: '2.0rem', 
                            position: 'relative', 
                            top: '15px' 
                          }}>/</span>
                          </Col>

                          <Col xs="6">
                            <Form.Group>
                              <label>Unité de temps</label>
                              <Form.Control
                                as="select"
                                name="unitTemps"
                                value={newArticle.unitTemps}
                                onChange={handleInputChange}
                              >
                                <option value="">Sélectionner une unité de temps</option>
                                {unitTempsOptions.map(option => (
                                  <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="2">
                        <Form.Group>
                          <label>Code TVA</label>
                          <Form.Control
                            type="text"
                            name="codeTVA"
                            value={newArticle.codeTVA}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      
                      <Col md="2">
                        <Form.Group>
                          <label>Coût de revient/AP (H)</label>
                          <Form.Control
                            type="text"
                            name="coutRevient"
                            value={newArticle.coutRevient}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>

              <Row>
                <Col md="8">
                  <Card className>
                    <Card.Header>
                      <Card.Title as="h5" style={welcomeStyle2} className='text-center py-2'>Spécificités ajoutées</Card.Title>
                    </Card.Header>
                    <Card.Body className="table-full-width table-responsive px-0 py-1"> {/* Réduit le padding vertical */}
                    <Table className="table-hover table-striped">
                      <thead>
                        <tr>
                          <th className="border-0">Actions</th>
                          <th className="border-0">Désignation 1</th>
                          <th className="border-0">Majoration (%)</th>
                        </tr>
                      </thead>
                      <tbody>
                      {newArticle.specifications && newArticle.specifications.map((spec, index) => (
                          <tr key={index}>
                            <td>
                              <FaTrash
                                style={{ cursor: 'pointer', color: 'red' }}
                                onClick={() => handleRemoveSpecification(index)}
                              />
                            </td>
                            <td>{spec.designation1}</td>
                            <td>{spec.majoration}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Button Card */}
                <Col md="4">
                  <Card className=" d-flex flex-column justify-content-center"> {/* Flexbox for centering */}
                    <Card.Body className="text-center"> {/* Center the text */}
                      <Button
                        variant="info"
                        onClick={handleAddSpecification}
                        className="w-100 mb-3" // Added padding for larger button
                      >
                        <FaPlus /> Ajouter une spécificité
                      </Button>
                      <Button
                        variant="success"
                        onClick={handleAddNewArticle}
                        className="w-100" // Added padding for larger button
                        disabled={!isModified || isFormEmpty()}
                      >
                        Enregistrer l'Article
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {/* Modal for adding a specification */}
              <Modal show={showSpecificationModal} onHide={() => setShowSpecificationModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Ajout d'une spécificité</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group>
                    <label>Code Spécificité</label>
                    <Form.Control
                      type="text"
                      value={newSpecification.code}
                      onChange={(e) => setNewSpecification({ ...newSpecification, code: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label>Désignation 1</label>
                    <Form.Control
                      type="text"
                      value={newSpecification.designation1}
                      onChange={(e) => setNewSpecification({ ...newSpecification, designation1: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label>Désignation 2</label>
                    <Form.Control
                      type="text"
                      value={newSpecification.designation2}
                      onChange={(e) => setNewSpecification({ ...newSpecification, designation2: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label>Majoration (%)</label>
                    <Form.Control
                      type="text"
                      value={newSpecification.majoration}
                      onChange={(e) => setNewSpecification({ ...newSpecification, majoration: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group style={{ display: 'flex', justifyContent: 'center' }}>
                    <label style={{ paddingTop:'15px', marginRight: '15px' }} htmlFor="manual-checkbox">Fenetre de saisie ?</label>
                    <input
                      id="manual-checkbox"
                      type="checkbox"
                      checked={newSpecification.isManual}
                      onChange={(e) => setNewSpecification({ ...newSpecification, isManual: e.target.checked })}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowSpecificationModal(false)}>
                    Annuler
                  </Button>
                  <Button variant="primary" onClick={handleSaveSpecification}>
                    Enregistrer
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>
        </Container>
        );
      }

    return (
      <Container fluid>
        {isMobile ? (
        renderStep()
      ) : (
          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h4" style={welcomeStyle}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>Création d'articles</span>
                      <div style={{ marginLeft: 'auto' }}>
                        <FaInfoCircle style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleHelpPopupShow} />
                      </div>
                    </div>
                  </Card.Title>
                  <div>
                    <Modal show={showHelpPopup} onHide={handleHelpPopupClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Aide</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                        Pour compléter le formulaire de devis de manière correcte et efficace, veuillez suivre les instructions ci-dessous :
                        </p>
                        <p>
                          - Vérifiez les coordonnées 
                        </p>
                        <p>
                          - Renseignez tous les champs
                        </p>
                        <p>
                          - Détaillez les services/produits
                        </p>
                        <p>
                          - Revérifiez les informations
                        </p>
                        <p>
                        Chaque champ est crucial pour la validité du devis. Une donnée manquante ou incorrecte peut entraîner des retards et des complications. Merci de votre coopération.
                        </p>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label>Etablissement</label>
                          <Form.Control
                            as="select"
                            value={selectedEtab}
                            onChange={handleEtabChange}
                          >
                            <option value="">Sélectionner un établissement</option>
                            {Object.keys(etablissements).map((key) => (
                              <option key={key} value={key}>{key + " - " + etablissements[key].etab_nom}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group>
                          <label>Code Article</label>
                          <div style={{ position: 'relative' }}>
                            {!newArticle.isCustom && (
                              <Select
                                placeholder="Choisir un article..."
                                options={articles} // Liste des articles récupérés via l'API
                                value={articles.find(article => article.value === newArticle.code)} // L'article sélectionné
                                onChange={(selectedOption) => {
                                  if (selectedOption) {
                                    setNewArticle({ ...newArticle, code: selectedOption.value, isCustom: false });
                                  } else {
                                    handleClearForm();
                                  }
                                }}
                                onInputChange={(inputValue) => {
                                  setTempCode(inputValue); // Stocker la valeur saisie dans une variable temporaire
                                }}
                                isClearable={true} // Ajoute la possibilité de supprimer la sélection actuelle
                                noOptionsMessage={() => "Aucune option"} // Message personnalisé pour "No Options"
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    borderColor: '#ced4da', // Remet le contour
                                    boxShadow: 'none',
                                    backgroundColor: '#fff',
                                    padding: '0',
                                    height: '40px',
                                    borderRadius: '4px', // Coin arrondi standard
                                  }),
                                }}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    setNewArticle({ ...newArticle, code: tempCode, isCustom: true }); // Sauvegarder la valeur temporaire dans l'input
                                  }
                                }}
                                onBlur={() => {
                                  if (!articles.some(article => article.label.toLowerCase().includes(tempCode.toLowerCase()))) {
                                    setNewArticle({ ...newArticle, code: tempCode, isCustom: true }); // Sauvegarder la valeur temporaire dans l'input
                                  }
                                }}
                              />
                            )}
                            {newArticle.isCustom && (
                              <Form.Control
                                type="text"
                                name="code"
                                value={newArticle.code}
                                onChange={(e) => {
                                  if (e.target.value === '') {
                                    setNewArticle({ ...newArticle, code: '', isCustom: false });
                                  } else {
                                    setNewArticle({ ...newArticle, code: e.target.value });
                                  }
                                }}
                                placeholder="Entrer un code d'article..."
                                style={{
                                  width: '100%',
                                  height: '40px',
                                  padding: '0 12px', // Centrage du texte en ajoutant du padding
                                  border: '1px solid #ced4da', // Remet le contour
                                  backgroundColor: '#fff',
                                  textAlign: 'left', // Align the input text to the left
                                  borderRadius: '4px', // Coin arrondi standard pour correspondre au Select
                                }}
                              />
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Form.Group>
                          <label>Désignation</label>
                          <Form.Control
                            type="text"
                            name="title"
                            value={newArticle.title}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>Désignation 2</label>
                          <Form.Control
                            type="text"
                            name="title2"
                            value={newArticle.title2}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>Code Activité</label>
                          <Form.Control
                            type="number"
                            name="codeActivite"
                            value={newArticle.codeActivite}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>Activité</label>
                          <Form.Control
                            as="select"
                            name="service"
                            value={newArticle.service}
                            onChange={handleInputChange}
                            disabled={newArticle.codeActivite === "0013" || newArticle.codeActivite === "0020"} // Grise le champ si le code d'activité est 0013 ou 0020
                          >
                            <option value="Menage">Ménage</option>
                            <option value="Espace Vert">Espace Vert</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Form>
                    <Row className="align-items-center">
                      <Col md="2">
                        <Form.Group>
                          <label>Nombre</label>
                          <Form.Control
                            type="number"
                            name="number"
                            value={newArticle.number}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="2">
                        <Form.Group>
                          <label>Unité de mesure</label>
                          <Form.Control
                            as="select"
                            name="unitMesure"
                            value={newArticle.unitMesure}
                            onChange={handleInputChange}
                          >
                            <option value="">Sélectionner une unité de mesure</option>
                            {unitMesureOptions.map(option => (
                              <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="1" className="text-center">
                        <span style={{ 
                          fontWeight: 'bold', 
                          fontSize: '2.0rem', 
                          position: 'relative', 
                          top: '15px' 
                        }}>/</span>
                      </Col>
                      <Col md="2">
                      <Form.Group>
                        <label>Unité de temps</label>
                        <Form.Control
                          as="select"
                          name="unitTemps"
                          value={newArticle.unitTemps}
                          onChange={handleInputChange}
                        >
                          <option value="">Sélectionner une unité de temps</option>
                          {unitTempsOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <label>Code TVA</label>
                          <Form.Control
                            type="text"
                            name="codeTVA"
                            value={newArticle.codeTVA}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="2">
                        <Form.Group>
                          <label>Coût de revient/AP (H)</label>
                          <Form.Control
                            type="text"
                            name="coutRevient"
                            value={newArticle.coutRevient}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>

              {/* Row to hold Specifications Table and Buttons */}
              <Row>
                {/* Specifications Table Card */}
                <Col md="8">
                  <Card className="mr-3"> {/* Added h-100 class */}
                    <Card.Header>
                      <Card.Title as="h5" style={welcomeStyle2} className='text-center py-2'>Spécificités ajoutées</Card.Title>
                    </Card.Header>
                    <Card.Body className="table-full-width table-responsive px-0 py-1"> {/* Réduit le padding vertical */}
                    <Table className="table-hover table-striped">
                      <thead>
                        <tr>
                          <th className="border-0">Actions</th>
                          <th className="border-0">Code Spécificité</th>
                          <th className="border-0">Désignation 1</th>
                          <th className="border-0">Désignation 2</th>
                          <th className="border-0">Majoration (%)</th>
                        </tr>
                      </thead>
                      <tbody>
                      {newArticle.specifications && newArticle.specifications.map((spec, index) => (
                          <tr key={index}>
                            <td>
                              <FaTrash
                                style={{ cursor: 'pointer', color: 'red' }}
                                onClick={() => handleRemoveSpecification(index)}
                              />
                            </td>
                            <td>{spec.code}</td>
                            <td>{spec.designation1}</td>
                            <td>{spec.designation2}</td>
                            <td>{spec.majoration}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Button Card */}
                <Col md="4">
                  <Card className=" d-flex flex-column justify-content-center"> {/* Flexbox for centering */}
                    <Card.Body className="text-center"> {/* Center the text */}
                      <Button
                        variant="info"
                        onClick={handleAddSpecification}
                        className="w-100 mb-3" // Added padding for larger button
                      >
                        <FaPlus /> Ajouter une spécificité
                      </Button>
                      <Button
                        variant="success"
                        onClick={handleAddNewArticle}
                        className="w-100" // Added padding for larger button
                        disabled={!isModified || isFormEmpty()}
                      >
                        Enregistrer l'Article
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {/* Modal for adding a specification */}
              <Modal show={showSpecificationModal} onHide={() => setShowSpecificationModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Ajout d'une spécificité</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group>
                    <label>Code Spécificité</label>
                    <Form.Control
                      type="text"
                      value={newSpecification.code}
                      onChange={(e) => setNewSpecification({ ...newSpecification, code: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label>Désignation 1</label>
                    <Form.Control
                      type="text"
                      value={newSpecification.designation1}
                      onChange={(e) => setNewSpecification({ ...newSpecification, designation1: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label>Désignation 2</label>
                    <Form.Control
                      type="text"
                      value={newSpecification.designation2}
                      onChange={(e) => setNewSpecification({ ...newSpecification, designation2: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label>Majoration (%)</label>
                    <Form.Control
                      type="text"
                      value={newSpecification.majoration}
                      onChange={(e) => setNewSpecification({ ...newSpecification, majoration: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group style={{ display: 'flex', justifyContent: 'center' }}>
                    <label style={{ paddingTop:'15px', marginRight: '15px' }} htmlFor="manual-checkbox">Fenetre de saisie ?</label>
                    <input
                      id="manual-checkbox"
                      type="checkbox"
                      checked={newSpecification.isManual}
                      onChange={(e) => setNewSpecification({ ...newSpecification, isManual: e.target.checked })}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowSpecificationModal(false)}>
                    Annuler
                  </Button>
                  <Button variant="primary" onClick={handleSaveSpecification}>
                    Enregistrer
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>
        )}
      </Container>
    );
  }

  export default Articles;
